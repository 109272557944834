import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

let baseUrl = process.env.REACT_APP_ENQUIRY_BASE_URL + "";
const localhostUrl = 'http://localhost:8000'
const nodeBaseUrl = process.env.REACT_APP_NODE_BASE_URL + "";


export async function checkBlockedUser(phone: any) {
    // const phoneNew = phone.slice(3)
    const user: any = await checkLogin();
    const accessToken = user ? user?.accessToken : ''
    console.log(user)
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/users/validate`,
            method: "POST",
            headers: {
                "Authorization": "Bearer " + accessToken
            },
            data: {
                contact: phone
            }
        })
            .then((res) => {
                console.log(res);

                resolve({ response: res.data })
            })
            .catch((err) => {
                console.log(err);

                reject({ error: err.response.status })
            });

    });
}

export async function checkUserExists(phone: any) {
    // const phoneNew = phone.slice(3)
    const user: any = await checkLogin();
    const accessToken = user ? user?.accessToken : ''
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/users/checkUser`,
            method: "POST",
            headers: {
                "Authorization": "Bearer " + accessToken
            },
            data: {
                contact: phone
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                console.log(err);

                reject({ error: err.response.status })
            });

    });
}

export function loginNew(formData: { token: string }) {

    return new Promise<{ response: any }>((resolve, reject) => {
        console.log("LOGN NEW :", formData);

        axios({
            url: `${nodeBaseUrl}/firebase/auth`,
            method: "POST",
            headers: {
                Accept: "application/json",
                "x-access-token": formData.token
            }
            // data: formData
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function signupNew(formData: { token: string, email: string, phone: string }) {
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/firebase/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                "x-access-token": formData.token
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function getLoginWhatsappUrlAPI() {
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `https://api.otpless.app/v1/client/user/session/initiate`,
            method: "POST",
            headers: {
                Accept: "application/json",
                appId: process.env.REACT_APP_OTPLESS_APPID + ""
            },
            data: {
                "loginMethod": "WHATSAPP",
                "redirectionURL": process.env.REACT_APP_FRONTEND_URL
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function loginWithWhatsappAPI(token: string) {
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/social-login/whatsapp`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: {
                token: token
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function loginWithWaIdAPI(waId: string) {
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/social-login/whatsapp-waid`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: {
                waId: waId
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

// export function addNewUserAddress({ uid, reqBody, token }: { uid: string, reqBody: any, token: string }) {
//     return new Promise<{ response: any }>((resolve, reject) => {
//         axios({
//             url: `${localhostUrl}/users/addAddress/${uid}`,
//             method: "PUT",
//             headers: {
//                 Accept: "application/json",
//             },
//             data: reqBody
//         })
//             .then((res) => {
//                 resolve({ response: res.data })
//             })
//             .catch((err) => {
//                 reject({ error: err.response.status })
//             });

//     });
// }

export async function addNewUserAddress({ reqBody }: { reqBody: any }) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/users/addAddress`,
            method: "POST",
            headers: {
                "x-access-token": authToken
            },
            data: reqBody
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function getUserAddress(formData: { token: string }) {
    const { token } = formData
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/users/getAddress`,
            method: "GET",
            headers: {
                "x-access-token": token
            },
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function updateUserAddress(formData: { id: string, reqBody: any, token: string }) {
    const { id, reqBody, token } = formData
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/users/updateAddress/${id}`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                "x-access-token": token
            },
            data: reqBody
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function deleteUserAddress(formData: { id: string, token: string }) {
    const { id, token } = formData
    return new Promise<{ response: any }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/users/deleteAddress/${id}`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "x-access-token": token
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function editProfile(formData: any) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/users/update`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
                // Authorization: "Bearer "+(token?token:'')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function reqResetPassword(formData: { email: string }) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrl}/reset-passwords`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function checkResetPasswordLinkValidity(formData: { id: string }) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrl}/reset-passwords/${formData.id}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}


export function resetPassword(formData: { id: string, newPassword: string }) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrl}/reset-passwords/reset`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

import './App.css';
import { Site } from 'Site';
import { NotificationsProvider, showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { loginNew, loginWithWhatsappAPI } from 'app/api/auth';
import { customFirebaseLogin } from 'app/api/firebase';
import { loginFromDB, setBlocked } from 'app/reducer/auth.reducer';
import { useAppDispatch } from 'app/hooks';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOverlay } from '@mantine/core';
import { makeOtplesssFloaterInVisible } from 'app/utils/otpless';

function App() {
  const dispatch = useAppDispatch();
  const [loggedInAlert, setLoggedInAlert] = useState(false);
  const [whatsappLoginLoader, setWhatsappLoginLoader] = useState(false);

  
  function addCustomCSS() {
    const style = document.createElement("style");
    console.log("don")
    style.innerHTML = `
          div#otpless-floating-button {
            display: block !important;
          }
    
      `;
    document.head.appendChild(style);
  }

  useEffect(() => {
    if (loggedInAlert) {
      showNotification({
        // title: 'Default notification',
        message: 'Successfully logged in',
        disallowClose: false,
        onClose: () => console.log('unmounted'),
        onOpen: () => console.log('mounted'),
        autoClose: 5000,
        icon: <FontAwesomeIcon icon={faCheckCircle} />,
        loading: false,
        color: 'teal'
      })
    }
  }, [loggedInAlert])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("qr_ref_id")) {
      console.log(searchParams.get("qr_ref_id"))
      addCustomCSS();
    };
  }, [])

  useEffect(() => {
    // Define the 'otpless' function
    window.otpless = (otplessUser) => {
      console.log(otplessUser)
      // alert(JSON.stringify(otplessUser));
      const otplesstoken = otplessUser?.token
      loginWithWhatsappAPI(otplesstoken)
        .then(res => {
          console.log(res)
          // alert("HEY")
          const firebasetoken = res.response.data.token
          customFirebaseLogin(firebasetoken)
            .then((firebaseResponse: any) => {
              console.log("Custom singin firebase response = ", firebaseResponse)
              loginNew({ token: firebaseResponse.stsTokenManager.accessToken })
                .then(
                  (res) => {
                    console.log("DATA", res);
                    dispatch(loginFromDB(res.response));
                    setWhatsappLoginLoader(false)
                    setLoggedInAlert(true)
                    makeOtplesssFloaterInVisible()
                  }
                ).catch((err) => {
                  console.log("DATA", err);
                  setWhatsappLoginLoader(false)
                  setLoggedInAlert(false)
                })
            })
            .catch(err => {
              setWhatsappLoginLoader(false)
              setLoggedInAlert(false)
              console.log("Custom singin firebase error = ", err)
            })
        })
        .catch(err => {
          setWhatsappLoginLoader(false)
          setLoggedInAlert(false)
          console.log(err)
        })
    };
  }, []);


  return (
    <NotificationsProvider position='bottom-center' zIndex={100000}>
      <div className="App">
        <LoadingOverlay visible={whatsappLoginLoader} />
        <Site />
      </div>
    </NotificationsProvider>
  );
}

export default App;

import { AsyncThunkAction, createAsyncThunk, createSlice, PayloadAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';
import { searchShipper, SearchData, createOrder, OrderData, UpdateData, confirmOrder, updateQuote, searchShipper4W, SearchData4W, SearchData4WRental, searchShipper4WRental, createOrder4W, confirmOrder4W, confirmRentalOrder, createOrderRental, RentalOrderData, UpdateRentalData, updateRentalQuote } from 'app/api/order';
import { AppThunk, RootState } from 'app/store';
import { getUser } from 'app/reducer/auth.reducer';

export interface Address {
    identifier?: string,
    address: string,
    pincode: number,
    lat: number,
    lng: number,
    state?: string,
    city?: string,
    country?: string
}

export interface Order {
    shipper: {
        name: string,
        fullName: string,
        logoURL: string
    },
    estimate: {
        time: number,
        cost: number,
        costWhelper?: number,
        costBeforeDiscount?: number,
        gst?: number
        platformCharges?: number
    }
    coupon?: string
}

export interface Person {
    name?: string,
    phone?: string,
    address?: {
        flatno?: string,
        building?: string
    },
}

export interface OrderState {
    mode: number;
    vehicleType4w: number;
    fetching: boolean;
    fetchingError: boolean;
    startAddress: Address;
    startAddressSearch: Address;
    startPerson: Person;
    endPerson: Person;
    endAddress: Address;
    endAddressSearch: Address;
    serviceType: number;
    orderDetails: Order;
    city: string,
    state: string,
    weight: number,
    rentalVehicle: string,
    rentalHours: number,
    cityBounds: any;
    results: any[] | undefined;
}

const initialState: OrderState = {
    mode: 1,
    vehicleType4w: NaN,
    fetching: false,
    fetchingError: false,
    startAddress: {
        identifier: '',
        address: '',
        pincode: 0,
        lat: 0,
        lng: 0,
        state: '',
        city: '',
        country: ''
    },
    startAddressSearch: {
        identifier: '',
        address: '',
        pincode: 0,
        lat: 0,
        lng: 0,
        city: '',
        country: '',
        state: ''
    },
    startPerson: {
        name: undefined,
        phone: undefined,
        address: {
            flatno: '',
            building: ''
        },
    },
    endPerson: {
        name: undefined,
        phone: undefined,
        address: {
            flatno: '',
            building: ''
        },
    },
    endAddress: {
        identifier: '',
        address: '',
        pincode: 0,
        lat: 0,
        lng: 0,
        state: '',
        city: '',
        country: ''
    },
    endAddressSearch: {
        identifier: '',
        address: '',
        pincode: 0,
        lat: 0,
        lng: 0,
        city: '',
        country: '',
        state: ''
    },
    serviceType: 0,
    orderDetails: {
        shipper: {
            name: '',
            fullName: '',
            logoURL: ''
        },
        estimate: {
            time: 0,
            cost: 0,
            costBeforeDiscount: 0
        }
    },
    city: '',
    state: '',
    weight: 0,
    rentalVehicle: '',
    rentalHours: 0,
    cityBounds: {
        sw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 }
    },
    results: undefined
}

export const confirmOrderCall = createAsyncThunk(
    'order/confirmOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        const response = await confirmOrder(data);
        return response.response;

    }
);

export const confirm4WOrderCall = createAsyncThunk(
    'order/confirm4WOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        const response = await confirmOrder4W(data);
        return response.response;

    }
);

export const confirmRentalOrderCall = createAsyncThunk(
    'order/confirmRentalOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        const response = await confirmRentalOrder(data);
        return response.response;

    }
);

const updateOrderCall = createAsyncThunk(
    'order/updateOrder',
    async (data: UpdateData) => {

        console.log("DATA: ", data);
        const response = await updateQuote(data);
        // The value we return becomes the `fulfilled` action payload
        let payload = {
            response: response.response,
            coupon: data.coupon
        }
        return payload

    }
);

const updateRentalOrderCall = createAsyncThunk(
    'order/updateRentalOrder',
    async (data: UpdateRentalData) => {

        console.log("DATA: ", data);
        const response = await updateRentalQuote(data);
        // The value we return becomes the `fulfilled` action payload
        let payload = {
            response: response.response,
            coupon: data.coupon
        }
        return payload

    }
);


export const updateRentalOrderDetailsAsync = (couponCode?: string): AppThunk => (
    dispatch,
    getState
) => {

    const start = getStartAddress(getState());
    const startPerson = getStartPerson(getState())
    const orderDetails = getOrderDetails(getState());
    const city = getCityName(getState());
    const weight = getWeight(getState());
    const vehicle = getRentalVehicle(getState())
    const rentalHours = getRentalHours(getState())
    const user = getUser(getState())

    let searchQuery: UpdateRentalData = {
        shipperID: orderDetails.shipper.name,
        start: {
            address: {
                flatno: startPerson.address?.flatno ? startPerson.address?.flatno : '',
                building: startPerson.address?.building ? startPerson.address?.building : '',
                fromGoogle: {
                    lat: start.lat,
                    lng: start.lng,
                    address: start.address,
                    pincode: start.pincode
                }
            },
            contact: {
                name: startPerson.name ? startPerson.name : '',
                phone: startPerson.phone ? startPerson.phone : ''
            }
        },
        city: city,
        weight: weight,
        vehicle: vehicle,
        hours: rentalHours,
        user: user.uid
    }

    if (couponCode) searchQuery.coupon = couponCode;

    console.log("DATA", searchQuery);

    dispatch(updateRentalOrderCall(searchQuery));

};

export const updateOrderDetailsAsync = (couponCode?: string): AppThunk => (
    dispatch,
    getState
) => {

    const start = getStartAddress(getState());
    const startPerson = getStartPerson(getState())
    const end = getEndAddress(getState());
    const endPerson = getEndPerson(getState())
    const orderDetails = getOrderDetails(getState());
    const user = getUser(getState());
    const city = getCityName(getState());
    const weight = getWeight(getState());
    const vehicle = getMode(getState());
    const hrs = getRentalHours(getState())
    const rentalVehicle = getRentalVehicle(getState())

    // const { 
    //     sender_address_line1,
    //     sender_address_line2,
    //     sender_name,
    //     sender_phone,
    //     recipient_address_line1,
    //     recipient_address_line2,
    //     recipient_name,
    //     recipient_phone,
    //     item_type
    // } = formData;

    let searchQuery: UpdateData = {
        shipperID: orderDetails.shipper.name,
        start: {
            address: {
                flatno: startPerson.address?.flatno ? startPerson.address?.flatno : '',
                building: startPerson.address?.building ? startPerson.address?.building : '',
                fromGoogle: {
                    lat: start.lat,
                    lng: start.lng,
                    address: start.address,
                    pincode: start.pincode
                }
            },
            contact: {
                name: startPerson.name ? startPerson.name : '',
                phone: startPerson.phone ? startPerson.phone : ''
            }
        },
        end: {
            address: {
                flatno: endPerson.address?.flatno ? endPerson.address?.flatno : '',
                building: endPerson.address?.building ? endPerson.address?.building : '',
                fromGoogle: {
                    lat: end.lat,
                    lng: end.lng,
                    address: end.address,
                    pincode: end.pincode
                }
            },
            contact: {
                name: endPerson.name ? endPerson.name : '',
                phone: endPerson.phone ? endPerson.phone : ''
            }
        },
        city: city,
        weight: weight,
        vehicle: vehicle,
        user: user.uid,
    }

    if (vehicle == 9) {
        searchQuery.hours = hrs
        searchQuery.rentalVehicle = rentalVehicle
    }

    if (couponCode) searchQuery.coupon = couponCode;

    console.log("DATA", searchQuery);

    dispatch(updateOrderCall(searchQuery));

};


const createOrderCall = createAsyncThunk(
    'order/createOrder',
    async (data: any) => {

        console.log("DATA: ", data.searchQuery);
        const response = await createOrder(data.searchQuery);
        // The value we return becomes the `fulfilled` action payload
        const payload = {
            res: response.response,
            callback: data.callback,
            isAdmin: data.isAdmin,
            prevData: data.searchQuery,
        }

        return payload;

    }
);

const create4WOrderCall = createAsyncThunk(
    'order/createOrder4W',
    async (data: any) => {

        console.log("DATA: ", data.searchQuery);
        const response = await createOrder4W(data.searchQuery);
        // The value we return becomes the `fulfilled` action payload
        const payload = {
            res: response.response,
            callback: data.callback,
            prevData: data.searchQuery
        }

        return payload;

    }
);

const createOrderRentalCall = createAsyncThunk(
    'order/createOrderRental',
    async (data: any) => {

        console.log("DATA: ", data.searchQuery);
        const response = await createOrderRental(data.searchQuery);
        // The value we return becomes the `fulfilled` action payload
        const payload = {
            res: response.response,
            callback: data.callback,
            prevData: data.searchQuery
        }

        return payload;

    }
);


export const createOrderAsync = (formData: any, callback: Function): AppThunk<any> => (
    dispatch,
    getState
) => {

    const start = getStartAddress(getState());
    const end = getEndAddress(getState());
    const orderDetails = getOrderDetails(getState());
    const user = getUser(getState());
    const weight = getWeight(getState());
    const city = getCityName(getState());
    const coupon = getCoupon(getState());
    const vehicle = getMode(getState());

    const {
        sender_address_line1,
        sender_address_line2,
        sender_name,
        sender_phone,
        sender_email,
        recipient_address_line1,
        recipient_address_line2,
        recipient_name,
        recipient_phone,
        item_type,
        helper,
    } = formData;

    let searchQuery: OrderData = {
        isSuperAdmin: user.isAdmin ? user.isAdmin : false,
        payment_status: "Initiated",
        user: user.uid,
        shipperID: orderDetails.shipper.name,
        start: {
            address: {
                fromGoogle: {
                    lat: start.lat,
                    lng: start.lng,
                    address: start.address,
                    pincode: start.pincode,
                    city: start.city,
                    state: start.state,
                    country: start.country
                },
                flatno: sender_address_line1.flatno,
                building: sender_address_line1.building,
                address_line_2: sender_address_line2
            },
            contact: {
                name: sender_name,
                phone: sender_phone.includes("+") ? sender_phone : ("+" + sender_phone),
                email: user.isAdmin ? sender_email : (user.email ? user.email : '')
            }
        },
        end: {
            address: {
                fromGoogle: {
                    lat: end.lat,
                    lng: end.lng,
                    address: end.address,
                    pincode: end.pincode,
                    city: end.city,
                    state: end.state,
                    country: end.country
                },
                flatno: recipient_address_line1.flatno,
                building: recipient_address_line1.building,
                address_line_2: recipient_address_line2
            },
            contact: {
                name: recipient_name,
                phone: recipient_phone.includes("+") ? recipient_phone : ("+" + recipient_phone)
            }
        },
        item: [item_type],
        weight: weight,
        city: city,
        order_type: "",
        vehicle: vehicle
    }

    if (vehicle === 2) {
        searchQuery.order_type = 'hyperlocal_2w'
    } else if (vehicle === 3) {
        searchQuery.order_type = 'hyperlocal_3w'
    } else if (vehicle === 4) {
        searchQuery.order_type = 'hyperlocal_4w'
    }

    let data = {
        searchQuery: searchQuery,
        callback: callback,
        isAdmin: user.isAdmin
    };

    if (coupon) searchQuery.coupon = coupon;

    if (vehicle !== 2) {
        searchQuery.helper = helper;
        // dispatch(create4WOrderCall(data));
    }

    dispatch(createOrderCall(data))
        .then(unwrapResult)
        .then((res) => {
            console.log("CCAvenue = ", res);
            var form = document.createElement("form") as HTMLFormElement
            form.setAttribute("method", "POST");
            form.setAttribute("action", "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction");
            form.setAttribute('id', 'nonseamless')
            form.setAttribute('name', 'redirect')

            var input1 = document.createElement("input");
            input1.setAttribute("name", "encRequest");
            input1.setAttribute("value", res.res.data.encRequest);
            input1.setAttribute("id", "encRequest");
            input1.setAttribute("type", "hidden");

            var input2 = document.createElement("input");
            input2.setAttribute("name", "access_code");
            input2.setAttribute("value", res.res.data.access_code);
            input2.setAttribute("id", "access_code");
            input2.setAttribute("type", "hidden");

            form.appendChild(input1)
            form.appendChild(input2)
            document.body.appendChild(form);
            console.log("FORM = ", form)
            alert("SUMBITTING FORM...")
            form.submit()

        });

};

export const createOrderRentalAsync = (formData: any, callback: Function): AppThunk => (
    dispatch,
    getState
) => {

    const start = getStartAddress(getState());
    const hours = getRentalHours(getState());
    const user = getUser(getState());
    const city = getCityName(getState());
    const orderDetails = getOrderDetails(getState());
    const vehicle = getRentalVehicle(getState());
    const coupon = getCoupon(getState())
    // const sender_phone = user.phone

    const {
        sender_name,
        sender_phone,
        sender_alt_phone,
        sender_address_line1,
        booked_at
    } = formData;

    let searchQuery: RentalOrderData = {
        isSuperAdmin: user.isAdmin ? user.isAdmin : false,
        uid: user.uid,
        hours: hours,
        shipperID: orderDetails.shipper.name,
        bookedAt: booked_at,
        city: city,
        vehicle: vehicle,
        start:{
            contact: {
                name: sender_name ? sender_name : (user.username ? user.username : ""),
                phone: sender_phone ? (sender_phone.includes("+") ? sender_phone : ("+" + sender_phone)) : user.phone,
                email: user.email ? user.email : "",
                alternatePhone: sender_alt_phone ? sender_alt_phone : ""
            },
            address: {
                flatno: sender_address_line1.flatno,
                building: sender_address_line1.building,
                fromGoogle: {
                    lat: start.lat,
                    lng: start.lng,
                    address: start.address,
                    pincode: start.pincode
                }
            },
        },
    }

    if (coupon) {
        searchQuery.coupon = coupon
    }

    let data = {
        searchQuery: searchQuery,
        callback: callback
    };

    console.log("SearchQuery data", data);

    dispatch(createOrderRentalCall(data));

};

const searchShipperCall = createAsyncThunk(
    'order/searchShipper',
    async (searchQuery: SearchData) => {
        const response = await searchShipper(searchQuery);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

const search4WShipperCall = createAsyncThunk(
    'order/search4WShipper',
    async (searchQuery: SearchData4W) => {
        const response = await searchShipper4W(searchQuery);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

const search4WShipperRentalCall = createAsyncThunk(
    'order/search4WRentalShipper',
    async (searchQuery: SearchData4WRental) => {
        const response = await searchShipper4WRental(searchQuery);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);


export const searchShipperWithTypeAsync = (): AppThunk => async (
    dispatch,
    getState
) => {

    let searchParam: SearchData = {
        start: getStartAddress(getState()),
        end: getEndAddress(getState()),
        city: getCityName(getState()),
        weight: getWeight(getState()),
        vehicle: getMode(getState())
    }

    // searchParam.start = getStartAddress(getState());
    // searchParam.end = getEndAddress(getState());
    // searchParam.vehicle = getServiceType(getState());

    await dispatch(searchShipperCall(searchParam));

};

export const search4WShipperWithTypeAsync = (): AppThunk => async (
    dispatch,
    getState
) => {

    let searchParam: SearchData4W = {
        start: getStartAddress(getState()),
        end: getEndAddress(getState()),
        city: getCityName(getState()),
        // weight: getWeight(getState()),
        // vehicle: getVehicleType4w(getState()),
        vehicle: getMode(getState())
    }

    // searchParam.start = getStartAddress(getState());
    // searchParam.end = getEndAddress(getState());
    // searchParam.vehicle = getServiceType(getState());

    await dispatch(search4WShipperCall(searchParam));

};


export const searchShipperRentalAsync = (): AppThunk => async (
    dispatch,
    getState
) => {

    let searchParam: SearchData4WRental = {
        city: getCityName(getState()),
        vehicle: getRentalVehicle(getState()),
        hours: getRentalHours(getState())
    }

    // searchParam.start = getStartAddress(getState());
    // searchParam.end = getEndAddress(getState());
    // searchParam.vehicle = getServiceType(getState());

    await dispatch(search4WShipperRentalCall(searchParam));

};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setStartAddressSearch: (state, action) => {
            // state.startAddress = action.payload;
            state.startAddressSearch = {
                ...state.startAddress,
                ...action.payload
            }
        },
        setEndAddressSearch: (state, action: PayloadAction<Address>) => {
            // state.endAddress = action.payload;
            state.endAddressSearch = {
                ...state.endAddress,
                ...action.payload
            }
        },
        setStartAddress: (state, action) => {
            // state.startAddress = action.payload;
            state.startAddress = {
                ...state.startAddress,
                ...action.payload
            }
        },
        setEndAddress: (state, action: PayloadAction<Address>) => {
            // state.endAddress = action.payload;
            state.endAddress = {
                ...state.endAddress,
                ...action.payload
            }
        },
        setStartPerson: (state, action) => {
            state.startPerson = {
                ...state.startPerson,
                ...action.payload
            }
        },
        setEndPerson: (state, action) => {
            state.endPerson = {
                ...state.endPerson,
                ...action.payload
            }
        },
        // setStartPerson: (state, action: PayloadAction<Person>) => {
        //     state.startPerson = action.payload;
        // },
        // updateStartPersonAddress: (state, action) => {
        //     state.startPerson.address = action.payload;
        // },
        // setEndPerson: (state, action: PayloadAction<Person>) => {
        //     state.endPerson = action.payload;
        // },
        // updateEndPersonAddress: (state, action) => {
        //     state.endPerson.address = action.payload;
        // },
        setOrderDetails: (state, action: PayloadAction<Order>) => {
            state.orderDetails = action.payload;
        },
        setvehicleType4wState: (state, action: PayloadAction<number>) => {
            state.vehicleType4w = action.payload;
        },
        setRentalVehicle: (state, action: PayloadAction<string>) => {
            state.rentalVehicle = action.payload;
        },
        setRentalHours: (state, action: PayloadAction<number>) => {
            state.rentalHours = action.payload;
        },
        setMode: (state, action: PayloadAction<number>) => {
            if (typeof action.payload == 'string') {
                state.mode = parseInt(action.payload);
            } else {
                state.mode = action.payload;
            }
        },
        setCityBounds: (state, action: PayloadAction<any>) => {
            state.cityBounds = action.payload.cityBounds;
            state.city = action.payload.city;
            if (action.payload.state) {
                state.state = action.payload.state;
            }
            state.startAddress = {
                identifier: '',
                address: '',
                pincode: 0,
                lat: 0,
                lng: 0
            };
            state.endAddress = {
                identifier: '',
                address: '',
                pincode: 0,
                lat: 0,
                lng: 0
            };
            state.startAddressSearch = {
                identifier: '',
                address: '',
                pincode: 0,
                lat: 0,
                lng: 0
            };
            state.endAddressSearch = {
                identifier: '',
                address: '',
                pincode: 0,
                lat: 0,
                lng: 0
            };
            localStorage.setItem('hyperlocalCity', action.payload.city);
        },
        setCityBoundsSafe: (state, action: PayloadAction<any>) => {
            state.cityBounds = action.payload.cityBounds;
            state.city = action.payload.city;
            localStorage.setItem('hyperlocalCity', action.payload.city);
        },
        setWeight: (state, action: PayloadAction<any>) => {
            state.weight = action.payload;
        },
        setCoupon: (state, action: PayloadAction<any>) => {
            state.orderDetails.coupon = action.payload;
        },
        clearHyperlocalShippers: (state) => {
            state.results = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchShipperCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(searchShipperCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.results = action.payload.data.data;
                // console.log("response from Shreyas: ", action.payload.data.data);
            })
            .addCase(searchShipperCall.rejected, (state) => {
                state.fetching = false
                state.results = []
            })

            .addCase(search4WShipperCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(search4WShipperCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.results = action.payload.data.data;
                // console.log("response from Shreyas: ", action.payload.data.data);
            })
            .addCase(search4WShipperCall.rejected, (state) => {
                state.fetching = false
                state.results = []
            })

            .addCase(search4WShipperRentalCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(search4WShipperRentalCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.results = action.payload.data.data;
                // console.log("response from Shreyas: ", action.payload.data.data);
            })
            .addCase(search4WShipperRentalCall.rejected, (state) => {
                state.fetching = false
                state.results = []
            })

            .addCase(createOrderCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(createOrderCall.fulfilled, (state, action) => {
                const { res, callback, prevData, isAdmin } = action.payload;
                const { estimate, order } = res.data.data;
                state.fetching = false;
                if (!isAdmin && callback) {
                    callback(order.orderID, order.razorpayID, estimate.amount, prevData);
                }
                else if (isAdmin) {
                    alert("Link sent to the user")
                }
            })
            .addCase(createOrderCall.rejected, (state) => {
                state.fetching = false
                alert("There was an error while creating an order")
            })

            .addCase(create4WOrderCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(create4WOrderCall.fulfilled, (state, action) => {
                const { res, callback, prevData } = action.payload;
                const { estimate, order } = res.data.data;
                state.fetching = false;
                callback(order.orderID, order.razorpayID, estimate.amount, prevData);
            })
            .addCase(create4WOrderCall.rejected, (state) => {
                state.fetching = false
            })

            .addCase(createOrderRentalCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(createOrderRentalCall.fulfilled, (state, action) => {
                const { res, callback, prevData } = action.payload;
                const { estimate, order } = res.data.data;
                state.fetching = false;
                callback(order.orderID, order.razorpayID, estimate.amount, prevData);
            })
            .addCase(createOrderRentalCall.rejected, (state) => {
                state.fetching = false
            })

            .addCase(confirmOrderCall.pending, (state) => {
                state.fetching = true;
                state.fetchingError = true;
            })
            .addCase(confirmOrderCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.fetchingError = false;
                // action.payload.navigate('/orders/'+action.payload.orderId);
            })
            .addCase(confirmOrderCall.rejected, (state) => {
                state.fetching = false
                alert("Something went wrong while confirming order.");
            })

            .addCase(confirm4WOrderCall.pending, (state) => {
                state.fetching = true;
                state.fetchingError = true;
            })
            .addCase(confirm4WOrderCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.fetchingError = false;
                // action.payload.navigate('/orders/'+action.payload.orderId);
            })
            .addCase(confirm4WOrderCall.rejected, (state) => {
                state.fetching = false
                alert("Something went wrong while confirming order.");
            })

            .addCase(confirmRentalOrderCall.pending, (state) => {
                state.fetching = true;
                state.fetchingError = true;
            })
            .addCase(confirmRentalOrderCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.fetchingError = false;
                // action.payload.navigate('/orders/'+action.payload.orderId);
            })
            .addCase(confirmRentalOrderCall.rejected, (state) => {
                state.fetching = false
                alert("Something went wrong while confirming order.");
            })

            .addCase(updateOrderCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(updateOrderCall.fulfilled, (state, action) => {
                // console.log("PAY", action.payload);
                const { breakdown, amount, time } = action.payload.response.data.data.estimate;
                state.orderDetails.estimate.cost = amount / 1;
                state.orderDetails.estimate.time = time
                state.orderDetails.estimate.costWhelper = (amount / 1) + breakdown.helperCost;
                state.orderDetails.estimate.costBeforeDiscount = breakdown.baseCost;
                state.orderDetails.estimate.gst = breakdown.gst;
                state.orderDetails.estimate.platformCharges = breakdown.platformChgs;
                state.fetching = false;
                if (action.payload.coupon) {
                    state.orderDetails.coupon = action.payload.coupon;
                } else {
                    state.orderDetails.coupon = undefined;
                }
            })
            .addCase(updateOrderCall.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateRentalOrderCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(updateRentalOrderCall.fulfilled, (state, action) => {
                const { response } = action.payload
                const { breakdown, amount } = response.data.data.estimate;
                state.orderDetails.estimate.cost = amount / 1;
                state.orderDetails.estimate.costBeforeDiscount = breakdown.baseCost;
                state.orderDetails.estimate.gst = breakdown.gst;
                state.orderDetails.estimate.platformCharges = breakdown.platformChgs;
                state.fetching = false;
                if (action.payload.coupon) {
                    state.orderDetails.coupon = action.payload.coupon;
                } else {
                    state.orderDetails.coupon = undefined;
                }
            })
            .addCase(updateRentalOrderCall.rejected, (state) => {
                state.fetching = false
            })
    }
})

export const { setMode, setStartAddressSearch, setStartAddress, setStartPerson, setEndAddressSearch, setEndAddress, setEndPerson, setOrderDetails, setvehicleType4wState, setRentalVehicle, setRentalHours, setCityBounds, setCityBoundsSafe, setWeight, clearHyperlocalShippers } = orderSlice.actions;

export const getMode = (state: RootState) => state.hyperlocal.mode;

export const getStartAddressSearch = (state: RootState) => state.hyperlocal.startAddressSearch;
export const getEndAddressSearch = (state: RootState) => state.hyperlocal.endAddressSearch;

export const getStartAddress = (state: RootState) => state.hyperlocal.startAddress;
export const getEndAddress = (state: RootState) => state.hyperlocal.endAddress;
export const getStartPerson = (state: RootState) => state.hyperlocal.startPerson;
export const getEndPerson = (state: RootState) => state.hyperlocal.endPerson;

export const getServiceType = (state: RootState) => state.hyperlocal.serviceType;
export const getVehicleType4w = (state: RootState) => state.hyperlocal.vehicleType4w;

export const getRentalVehicle = (state: RootState) => state.hyperlocal.rentalVehicle;
export const getRentalHours = (state: RootState) => state.hyperlocal.rentalHours;

export const getOrderDetails = (state: RootState) => state.hyperlocal.orderDetails;
export const getCityBounds = (state: RootState) => state.hyperlocal.cityBounds;
export const getCityName = (state: RootState) => state.hyperlocal.city;
export const getStateName = (state: RootState) => state.hyperlocal.state;
export const getWeight = (state: RootState) => state.hyperlocal.weight;

export const getCoupon = (state: RootState) => state.hyperlocal.orderDetails.coupon;

export const getFetching = (state: RootState) => state.hyperlocal.fetching;
export const getFetchingError = (state: RootState) => state.hyperlocal.fetchingError;


export default orderSlice.reducer;
import { faCity, faLocationCrosshairs, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Modal, UnstyledButton } from "@mantine/core";
import { getHyperlocalCitiesState } from "app/reducer/general.reducer";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCityName, getStateName, setCityBounds } from "../../../app/reducer/hyperlocal.reducer";
import { getIntCountryAlphaCode } from "../../../app/reducer/international.reducer";
import BackButton from "../../general/BackButton";
import PlacesAutocomplete from "../../maps/PlacesAutocomplete";
import PlacesAutocompleteIndia from "../../maps/PlacesAutocompleteIndia";
import PlacesAutocompleteInt from "../../maps/PlacesAutocompleteInt";

interface CityBound {
  cityString: string;
  cityText: string;
  sw: {
      lat: number;
      lng: number;
  };
  ne: {
      lat: number;
      lng: number;
  };
  state: string
}

interface PlacesModalInterface{
  opened: boolean;
  setOpened: Function;
}

export default function PlacesModal({opened, setOpened}:PlacesModalInterface): JSX.Element {

  const dispatch = useAppDispatch();

  const [cityFilter, setCityFilter] = useState<string>('')
  const city = useAppSelector(getCityName);
  const hyperlocalCityList = useAppSelector(getHyperlocalCitiesState);


  const selectCity = (city: string, cityBounds: { sw: { lat: number, lng: number }, ne: { lat: number, lng: number } }, state: string) => {

    dispatch(setCityBounds({ city, cityBounds, state }));
    localStorage.setItem('shipmantra-state', state)
    setOpened(false)

}
  

  return (
    <Modal
      radius={20} withCloseButton={true}
      opened={opened}
      onClose={() => setOpened(false)}
      size='940px'
    >
      <div className='d-flex flex-column' style={{ padding: '24px', backgroundColor: '#ffffff' }}>
        <div style={{display:'flex'}}>
          <Button variant='subtle' style={{color:'#461EAD'}} leftIcon={<FontAwesomeIcon icon={faLocationCrosshairs} />}>
            Detect My Location
          </Button>
          <div style={{flex:1}}>
              <Input variant='filled' mx='20px' icon={<FontAwesomeIcon icon={faSearch} />} placeholder="Search City" value={cityFilter} onChange={e=>setCityFilter(e.target.value)}/>
          </div>
        </div>
        <div className='widget-text' style={{ fontSize:'14px', fontWeight: 600, marginTop:'50px', marginBottom:'20px', color:'#461EAD', textAlign:'center'}}>
            <FontAwesomeIcon icon={faCity} />&nbsp;Select your city
        </div>
        <div style={{overflow:'scroll', width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-start', flexWrap:'wrap'}}>
            {
                (cityFilter?
                    hyperlocalCityList.filter(item => (item.cityText.toLowerCase().includes(cityFilter.toLowerCase())))
                    :
                    hyperlocalCityList
                    
                ).map((el: CityBound) => {
                    const { cityString, cityText, sw, ne, state } = el;

                    return (
                        //on hover color background: #F3F7FF;
                        <Button variant='subtle' key={cityString}
                            style={{ width:'130px', margin:'5px', color:'#101010'}}
                            onClick={() => selectCity(cityString, { sw, ne }, state)}
                        >
                            <div style={{ fontWeight: 700, textAlign:'start', fontSize: '12px'}}>{cityText}</div>
                            {/* <div style={{ fontSize: '12px', opacity:0.8 }}>{state}</div> */}
                        </Button>
                    )
                })
            }
        </div>
      </div>
    </Modal>
  );
}


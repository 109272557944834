import { AsyncThunkAction, createAsyncThunk, createSlice, PayloadAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';
import { searchShipper, SearchData, createOrder, OrderData, UpdateData, confirmOrder, updateQuote, searchShipper4W, SearchData4W, SearchData4WRental, searchShipper4WRental, createOrder4W, confirmOrder4W, confirmRentalOrder, createOrderRental, RentalOrderData, UpdateRentalData, updateRentalQuote } from '../../app/api/order';
import { AppThunk, RootState } from '../../app/store';
import { getUser } from '../../app/reducer/auth.reducer';
import { initApsrtcOrder } from '../../app/api/apsrtc/order';


export interface Address {
    identifier?: string,
    address: string,
    pincode: string,
    lat: number,
    lng: number,
    state: string,
    city: string,
    country?: string
}

export interface Order {
    shipper: {
        name: string,
        fullName: string,
        logoURL: string
    },
    estimate: {
        time: number,
        cost: number,
        costWhelper?: number,
        costBeforeDiscount?: number,
        gst?: number
        platformCharges?: number
    }
    coupon?: string
}

export interface Person {
    name?: string,
    phone?: string,
    email?: string,
    address?: {
        flatno?: string,
        building?: string
    },
}

export interface OrderState {
    fetching: boolean;
    fetchingError: boolean;
    startAddress: Address;
    startAddressSearch: Address;
    startPerson: Person;
    endPerson: Person;
    endAddress: Address;
    endAddressSearch: Address;
    serviceType: number;
    orderDetails: Order;
    package: any[];
    results: any[] | undefined;
    weight: number;
    startCityBounds: any;
    endCityBounds: any;
    orderData?: any;
    pkgValue?: any;
    goodsCategory?: any;
    startCity: string;
    endCity: string;
    packageImageURL: string;
    fromCounter: boolean;
    startAgentData: any;
    endAgentData: any;
    startSavedAddressUsed: boolean;
    startSavedAddress: undefined | {
        name: string,
        phone: string,
        flatno: string,
        building: string,
        fromGoogle?: any
    };
    pickupMethod: string;
    endSavedAddressUsed: boolean;
    endSavedAddress: undefined | {
        name: string,
        phone: string,
        flatno: string,
        building: string,
        fromGoogle?: any
    };
    dropMethod: string;
}



const initialState: OrderState = {
    fetching: false,
    fetchingError: false,
    startAddress: {
        identifier: '',
        address: '',
        pincode: '',
        lat: 0,
        lng: 0,
        state: '',
        city: '',
        country: ''
    },
    startAddressSearch: {
        identifier: '',
        address: '',
        pincode: '',
        lat: 0,
        lng: 0,
        city: '',
        country: '',
        state: ''
    },
    startPerson: {
        name: undefined,
        phone: undefined,
        address: {
            flatno: '',
            building: ''
        },
    },
    endPerson: {
        name: undefined,
        phone: undefined,
        address: {
            flatno: '',
            building: ''
        },
    },
    endAddress: {
        identifier: '',
        address: '',
        pincode: '',
        lat: 0,
        lng: 0,
        state: '',
        city: '',
        country: ''
    },
    endAddressSearch: {
        identifier: '',
        address: '',
        pincode: '',
        lat: 0,
        lng: 0,
        city: '',
        country: '',
        state: ''
    },
    startAgentData: undefined,
    endAgentData: undefined,
    serviceType: 0,
    orderDetails: {
        shipper: {
            name: '',
            fullName: '',
            logoURL: ''
        },
        estimate: {
            time: 0,
            cost: 0,
            costBeforeDiscount: 0
        }
    },
    startCityBounds: {
        sw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 }
    },
    endCityBounds: {
        sw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 }
    },
    fromCounter: false,
    results: undefined,
    package: [{}],
    weight: 0,
    pkgValue: 'rs5000',
    orderData: {},
    startCity: '',
    endCity: '',
    packageImageURL: '',
    startSavedAddressUsed: false,
    startSavedAddress: undefined,
    endSavedAddressUsed: false,
    endSavedAddress: undefined,
    pickupMethod: 'priority',
    dropMethod: 'priority'
}

export const createApsrtcOrderAsync = createAsyncThunk(
    'pan-india/initOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        let reqBody = data;
        if(!reqBody.start?.contact?.phone?.includes("+")){
            reqBody.start.contact.phone = "+" + reqBody.start.contact.phone
        }
        if(!reqBody.end?.contact?.phone?.includes("+")){
            reqBody.end.contact.phone = "+" + reqBody.end.contact.phone
        }
        console.log("REQ BOD: ", reqBody);
        const response = await initApsrtcOrder(reqBody);
        // The value we return becomes the `fulfilled` action payload
        return response;

    }
);


export const apsrtcOrderSlice = createSlice({
    name: 'apsrtc_order',
    initialState,
    reducers: {
        resetStartAddress: (state, action) => {
            state.startAddress = {
                identifier: '',
                address: '',
                pincode: '',
                lat: 0,
                lng: 0,
                state: '',
                city: '',
                country: ''
            }
        },
        resetEndAddress: (state, action) => {
            state.endAddress = {
                identifier: '',
                address: '',
                pincode: '',
                lat: 0,
                lng: 0,
                state: '',
                city: '',
                country: ''
            }
        },
        setStartAddressSearch: (state, action) => {
            // state.startAddress = action.payload;
            state.startAddressSearch = {
                ...state.startAddress,
                ...action.payload
            }
        },
        setEndAddressSearch: (state, action: PayloadAction<Address>) => {
            // state.endAddress = action.payload;
            state.endAddressSearch = {
                ...state.endAddress,
                ...action.payload
            }
        },
        setStartAddress: (state, action) => {
            // state.startAddress = action.payload;
            state.startAddress = {
                ...state.startAddress,
                ...action.payload
            }
        },
        setEndAddress: (state, action: PayloadAction<Address>) => {
            // state.endAddress = action.payload;
            state.endAddress = {
                ...state.endAddress,
                ...action.payload
            }
        },
        setStartPerson: (state, action) => {
            state.startPerson = {
                ...state.startPerson,
                ...action.payload
            }
        },
        setEndPerson: (state, action) => {
            state.endPerson = {
                ...state.endPerson,
                ...action.payload
            }
        },
        setOrderDetails: (state, action: PayloadAction<Order>) => {
            state.orderDetails = action.payload;
        },
        setStartAgentData: (state, action: PayloadAction<any>) => {
            state.startAgentData = action.payload;
        },
        setEndAgentData: (state, action: PayloadAction<any>) => {
            state.endAgentData = action.payload;
        },
        setCoupon: (state, action: PayloadAction<any>) => {
            state.orderDetails.coupon = action.payload;
        },
        setWeight: (state, action: PayloadAction<any>) => {
            state.weight = action.payload
        },
        setPkgDetails: (state, action: PayloadAction<any>) => {
            state.package = {
                ...state.package,
                ...action.payload
            }
        },
        setPackageData: (state, action: PayloadAction<any>) => {
            state.package = action.payload
        },
        setPackageImageUrlData: (state, action: PayloadAction<any>) => {
            state.packageImageURL = action.payload
        },
        setStartCityBounds: (state, action: PayloadAction<any>) => {
            state.startCityBounds = action.payload;
            // state.city = action.payload.city;
        },
        setEndCityBounds: (state, action: PayloadAction<any>) => {
            state.endCityBounds = action.payload.cityBounds;
            // state.city = action.payload.city;
        },
        setOrderData: (state, action: PayloadAction<any>) => {
            state.orderData = action.payload
        },
        setPackageValue: (state, action: PayloadAction<any>) => {
            state.pkgValue = action.payload
        },
        setGoodsCategory: (state, action: PayloadAction<any>) => {
            state.goodsCategory = action.payload
        },
        setApsrtcStartCity: (state, action: PayloadAction<any>) => {
            state.startCity = action.payload
        },
        setApsrtcEndCity: (state, action: PayloadAction<any>) => {
            state.endCity = action.payload
        },
        setFromCounter: (state, action: PayloadAction<any>) => {
            state.fromCounter = action.payload
        },
        setStartSavedAddressUsed: (state, action: PayloadAction<any>) => {
            state.startSavedAddressUsed = action.payload
        },
        setEndSavedAddressUsed: (state, action: PayloadAction<any>) => {
            state.endSavedAddressUsed = action.payload
        },
        setStartSavedAddress: (state, action: PayloadAction<any>) => {
            state.startSavedAddress = action.payload
        },
        setEndSavedAddress: (state, action: PayloadAction<any>) => {
            state.endSavedAddress = action.payload
        },
        setPickupMethod: (state, action: PayloadAction<any>) => {
            state.pickupMethod = action.payload;
        },
        setDropMethod: (state, action: PayloadAction<any>) => {
            state.dropMethod = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createApsrtcOrderAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(createApsrtcOrderAsync.fulfilled, (state, action) => {
                state.fetching = false;
            })
            .addCase(createApsrtcOrderAsync.rejected, (state) => {
                state.fetching = false
            })
    }
})

export const { setPickupMethod, setDropMethod, setPackageImageUrlData, setStartAgentData, setEndAgentData, setFromCounter, setStartSavedAddressUsed, setEndSavedAddressUsed, setStartAddressSearch, setStartSavedAddress, setEndSavedAddress, setStartAddress, setStartPerson, resetStartAddress, resetEndAddress, setEndAddressSearch, setStartCityBounds, setEndCityBounds, setApsrtcStartCity, setApsrtcEndCity, setPackageData, setPackageValue, setGoodsCategory, setOrderData, setPkgDetails, setWeight, setEndAddress, setEndPerson, setOrderDetails } = apsrtcOrderSlice.actions;


export const getStartAddressSearch = (state: RootState) => state.apsrtc.startAddressSearch;
export const getEndAddressSearch = (state: RootState) => state.apsrtc.endAddressSearch;

export const getStartAddress = (state: RootState) => state.apsrtc.startAddress;
export const getEndAddress = (state: RootState) => state.apsrtc.endAddress;
export const getStartPerson = (state: RootState) => state.apsrtc.startPerson;
export const getEndPerson = (state: RootState) => state.apsrtc.endPerson;
export const getPickupMethod = (state: RootState) => state.apsrtc.pickupMethod;
export const getDropMethod = (state: RootState) => state.apsrtc.dropMethod;

export const getStartAgentData = (state: RootState) => state.apsrtc.startAgentData;
export const getEndAgentData = (state: RootState) => state.apsrtc.endAgentData;

export const getServiceType = (state: RootState) => state.apsrtc.serviceType;

export const getOrderDetails = (state: RootState) => state.apsrtc.orderDetails;

export const getCoupon = (state: RootState) => state.apsrtc.orderDetails.coupon;

export const getFetching = (state: RootState) => state.apsrtc.fetching;
export const getFetchingError = (state: RootState) => state.apsrtc.fetchingError;

export const getStartCityBounds = (state: RootState) => state.apsrtc.startCityBounds;
export const getEndCityBounds = (state: RootState) => state.apsrtc.endCityBounds;
export const getWeight = (state: RootState) => state.apsrtc.weight
export const getPkgDetails = (state: RootState) => state.apsrtc.package

export const getOrderData = (state: RootState) => state.apsrtc.orderData
export const getPkgValue = (state: RootState) => state.apsrtc.pkgValue
export const getGoodsCategory = (state: RootState) => state.apsrtc.goodsCategory
export const getPkgImageUrl = (state: RootState) => state.apsrtc.packageImageURL

export const getStartCity = (state: RootState) => state.apsrtc.startCity
export const getEndCity = (state: RootState) => state.apsrtc.endCity

export const getFromCounter = (state: RootState) => state.apsrtc.fromCounter

export const getStartSavedAddressUsed = (state: RootState) => state.apsrtc.startSavedAddressUsed
export const getEndSavedAddressUsed = (state: RootState) => state.apsrtc.endSavedAddressUsed

export const getStartSavedAddress = (state: RootState) => state.apsrtc.startSavedAddress
export const getEndSavedAddress = (state: RootState) => state.apsrtc.endSavedAddress
export default apsrtcOrderSlice.reducer;

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithPhoneNumber, signOut, RecaptchaVerifier, signInWithCustomToken, User } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTS_ID
};

// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);
// import { getAnalytics } from "firebase/analytics";

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

const auth = getAuth();
// connectAuthEmulator(auth, 'http://localhost:9099');

// export function loginWithGmail() {

//     const provider = new GoogleAuthProvider();
//     return new Promise((resolve, reject) => {

//       signInWithPopup(auth, provider)
//       .then(async (result) => {
//           console.log("GOOGLECRED", result)
//           // This gives you a Google Access Token. You can use it to access the Google API.
//           const credential = GoogleAuthProvider.credentialFromResult(result);
//           if(credential){
//               const token = credential.accessToken;
//           }
//           // The signed-in user info.
//           const user = result.user;
//           const data = {
//             name: user.displayName,
//             email: user.email,
//             emailVerified: user.emailVerified,
//             photoURL: user.photoURL,
//             // createdAt: user.createdAt
//           };
//           const userDoc = await manageSignUp(user.uid,data);
//           resolve(userDoc);
//         // ...
//       }).catch((error) => {
//           // Handle Errors here.
//           // const errorCode = error.code;
//           // const errorMessage = error.message;
//           // The email of the user's account used.
//           const email = error.email;
//           // The AuthCredential type that was used.
//           // const credential = GoogleAuthProvider.credentialFromError(error);
//           // ...
//           reject(error);
//       });

//     })

// }

export function loginWithPhone(number: string) {

  return new Promise<void>((resolve, reject) => {

    window.recaptchaVerifier = new RecaptchaVerifier('phone-signin-btn', {
      'size': 'invisible',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("CAPTCHA", response)
      }
    }, auth);

    // alert('captcha verifying start')
    const appVerifier = window.recaptchaVerifier;
    console.log(appVerifier)
    // alert(appVerifier.toString())
    
    signInWithPhoneNumber(auth, number, appVerifier)
    .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // alert('cpatch verified. enter otp')
        resolve();
        // ...
      }).catch((error) => {
        reject(error);
        // Error; SMS not sent
        // ...
      });
    // ...
  })

}

export function loginWithPhoneOTP(otp: string) {

  return new Promise<any>((resolve, reject) => {

    const code = otp;

    window.confirmationResult.confirm(code).then((result: any) => {
      // User signed in successfully.
      const user = result.user;
      resolve(user);
      console.log("PHONE USER", user);
      // ...
    }).catch((error: any) => {
      reject(error);
      // User couldn't sign in (bad verification code?)
      // ...
    })
    // ...
  })

}

export function getLoggedInUser() {

  return new Promise((resolve, reject) => {

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        resolve(user)
        // ...
      } else {
        reject();
        // User is signed out
        // ...
      }
    });

  })

}

export function checkLogin1() {

  return new Promise<User>((resolve, reject) => {

    onAuthStateChanged(auth, async (user) => {

      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        console.log("LOGGED IN USER", user)
        resolve(user)
        // ...
      } else {
        reject();
        // User is signed out
        // ...
      }
    });

  })

}

export function customFirebaseLogin(token: string) {

  return new Promise((resolve, reject) => {

    signInWithCustomToken(auth, token)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        resolve(user)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        reject(error)
        // ...
      });

  })

}


export function signout() {

  return new Promise<void>((resolve, reject) => {

    signOut(auth).then(() => {
      // Sign-out successful.
      resolve();
    }).catch((error) => {
      // An error happened.
      reject(error);
    });

  })

}

export function checkLogin() {

  return new Promise<any>((resolve, reject) => {

    try{
      auth.onIdTokenChanged(async (user) => {
        let token = await user?.getIdToken();
        if(!token){
          reject();
        }
        resolve({stsTokenManager:{accessToken:token}}) //legacy support
  
      });
    }catch{
      reject();

    }

  })

}
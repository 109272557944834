import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useViewportSize } from '@mantine/hooks';

import { ANDHRAINNOVATION, FOOTER_FB, FOOTER_IG, FOOTER_LINKEDIN, FOOTER_PINTEREST, FOOTER_TWITTER, FOOTER_WHATSAPP, NASSCOM, SHIPMANTRA_TEXT_WHITE, THUB, WHATSAPP_QR_CODE } from "../../assets";
import ContactUsForm from "../popups/ContactUsForm";
import ShipperForm from "../popups/ShipperForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function Footer(none: {}): JSX.Element {
  const location = useLocation();

  const navigate = useNavigate();

  const [showContactForm, setShowContactForm] = useState(false);
  const [showShipperForm, setShowShipperForm] = useState(false);

  const { height, width } = useViewportSize();

  return (
    <section className="footer" style={{ backgroundColor: '#263363', color: '#fff', paddingBottom: '90px', marginBottom: '77px', display: ((location.pathname === '/' || width > 991) ? '' : 'none') }}>
      <Container>
        <Row>

          <Col md={12} lg={12}>
            <div className="footer-logo">
              <img
                src={SHIPMANTRA_TEXT_WHITE}
                alt="GoLintu logo"
              />
            </div>
          </Col>
        </Row>
        <Row>

          <Col md={4} lg={4}>
            <div className="footer-text footer-about-text" style={{ color: '#fff' }}>
              <b>Shipmantra™</b> is a marketplace for logistics with all kinds of shippers (carriers) from hyperlocal to International deliveries to fulfil your business or personal shipments. Shipmantra covers all categories of shippers and assists you to find the best shippers for your shipping needs.
            </div>
            <div className="footer-text footer-about-text" style={{ color: '#fff' }}>SHIPMANTRA - A Product from GOLINTU SERVICES PRIVATE LIMITED</div>
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '10pt' }}>
              Scan QR code to reach our Whatsapp support
              <img src={WHATSAPP_QR_CODE} className='footer-text' alt="Whatsapp support" style={{ height: '100px', width: '100px', marginTop: '10px' }} />
            </div>
          </Col>

          <Col md={6} lg={1}>
          </Col>

          <Col md={6} lg={3}>
            <div className="footer-links" style={{ marginTop: '20px' }}>
              <a href='javascript:void' onClick={() => navigate('get-in-touch')}>CONTACT US</a>
              <a target="_blank" rel="noreferrer" href='/become-our-agent'>BECOME OUR AGENT</a>
              <a target="_blank" rel="noreferrer" href='/terms-and-conditions' >TERMS AND CONDITIONS</a>
              <a target="_blank" rel="noreferrer" href='/faq' >FAQ</a>
              {/* <a href="javascript:void">Test</a> */}
            </div>
            <br />
            Get in touch: <br />
            <a href="mailto: support@shipmantra.com"><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;support@shipmantra.com</a><br />
            <a href="tel:08069459527"><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;08069459527</a><br />
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}><FontAwesomeIcon style={{ marginRight: '13px', marginTop: '2px' }} icon={faLocationDot} /><div style={{ lineHeight: 1.1, fontSize: '15px' }}>D No : 52-10-41/1/3, Resapuvanipalem Visakhapatnam, Andhra Pradesh, 530013</div></div>
          </Col>

          <Col md={6} lg={4}>
            <div className="footer-links" style={{ marginTop: '20px', marginBottom: '20px' }}>
              Associated with
            </div>
            <div style={{ padding: '5px', opacity: '0.8' }}>
              <img alt="nasscom logo" src={NASSCOM} style={{ height: '50px', filter: 'brightness(10)' }} />
            </div>
            <div style={{ padding: '5px', opacity: '0.8' }}>
              <img alt="t-hub logo" src={THUB} style={{ marginLeft: '-20px', height: '120px', filter: 'contrast(0) brightness(10)' }} />
              <img alt="t-hub logo" src={ANDHRAINNOVATION} style={{ height: '120px', filter: ' brightness(2)' }} />
            </div>
          </Col>

        </Row>

      </Container>
      <section>
        <Container>

          <Row>

            <Col md={4} lg={4}>
              <p className="footer-text footer-copyright-text" style={{ color: '#fff', marginTop: '20px' }}>
                <span className="copyright-symbol">©&nbsp;</span>Copyright 2023 GoLintu.&nbsp;- All Rights Reserved
              </p>
            </Col>

            <Col md={6} lg={1}>       

            </Col>

            <Col md={6} lg={3}>
              <div className="footer-social-media">
                <a href="#"><img style={{ height: '32px', width: '32px', marginRight: '8px' }} src={FOOTER_IG} /></a>
                <a href="https://twitter.com/shipmantra" target="_blank" rel="noreferrer"><img style={{ height: '32px', width: '32px', marginRight: '8px' }} src={FOOTER_TWITTER} /></a>
                <a href="#"><img style={{ height: '32px', width: '32px', marginRight: '8px' }} src={FOOTER_PINTEREST} /></a>
                <a href="https://www.linkedin.com/company/shipmantra/?viewAsMember=true" target="_blank" rel="noreferrer"><img style={{ height: '32px', width: '32px', marginRight: '8px' }} src={FOOTER_LINKEDIN} /></a>
                <a href="https://www.facebook.com/Shipmantra" target="_blank" rel="noreferrer"><img style={{ height: '32px', width: '32px', marginRight: '8px' }} src={FOOTER_FB} /></a>
                <a href="https://wa.me/message/WRL4N6BHGLRJC1" target="_blank" rel="noreferrer"><img style={{ height: '32px', width: '32px' }} src={FOOTER_WHATSAPP} /></a>
                {/* <FontAwesomeIcon icon={faInstagramSquare} /> <FontAwesomeIcon icon={faFacebookSquare} /> <FontAwesomeIcon icon={faPinterestSquare} /> <FontAwesomeIcon icon={faTwitterSquare} /> <FontAwesomeIcon icon={faLinkedin} /> */}
              </div>
            </Col>

          </Row>

          {/* <Row>
            <Col md={4} lg={4}></Col>

            <Col md={6} lg={1}></Col>

            <Col md={6} lg={3}>
              <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
                Scan QR code to reach our Whatsapp support
                <img src={WHATSAPP_QR_CODE} alt="Whatsapp support" style={{ height: '100px', width: '100px', marginTop: '10px' }} />
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>
      <ContactUsForm isVisible={showContactForm} handleClose={() => setShowContactForm(false)} />
      <ShipperForm isVisible={showShipperForm} handleClose={() => setShowShipperForm(false)} />
    </section>

  );
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { confirmExpressDelOrder, initExpressDelOrder, searchSameDayDelShipper } from "app/api/panindia/expressdelivery";
import { createRequestPet, searchPetShipper } from "app/api/panindia/pet-logistics";
import { AppThunk, RootState } from "app/store";
import { getPanIndiaEndAddress, getPanIndiaEndCity, getPanIndiaService, getPanIndiaStartAddress, getPanIndiaStartCity } from "app/reducer/panindia/panindia.reducer";

export interface OrderState {
    fetching: boolean;
    fetchingError: boolean;
    results: any[] | undefined;
    pet_type: string;
    vaccinated: boolean;
    licensed: boolean;
    medically_fit: boolean;
}

const initialState: OrderState = {
    fetching: false,
    fetchingError: false,
    results: undefined,
    pet_type: '',
    vaccinated: false,
    licensed: false,
    medically_fit: false
}

export interface SearchData {
    service: string | undefined,
    start: {
        originPin: string,
        originState: string | undefined,
        originCity: string | undefined
    },
    end: {
        destinationPin: string,
        destinationState: string | undefined,
        destinationCity: string | undefined
    }
}

const searchPetShipperCall = createAsyncThunk(
    '/pet-logistics/searchPetShipper',
    async (searchQuery: SearchData) => {
        const response = await searchPetShipper(searchQuery)
        return response.response
    }
)

export const createPetRequestAsync = createAsyncThunk(
    'pet-logistics/createPetRequest',
    async (data: any) => {
        console.log("DATA: ", data);
        const response = await createRequestPet(data);
        // The value we return becomes the `fulfilled` action payload
        return response;

    }
);

export const searchPetShipperAsync = (): AppThunk => async (
    dispatch,
    getState
) => {
    const startAddress = getPanIndiaStartAddress(getState())
    const endAddress = getPanIndiaEndAddress(getState())
    const startCity = getPanIndiaStartCity(getState())
    const endCity = getPanIndiaEndCity(getState())
    let searchParams: SearchData = {
        service: 'pet-logistics',
        start: {
            originPin: startAddress.pincode.toString(),
            originState: startAddress.state?.toString(),
            originCity: startCity
        },
        end: {
            destinationPin: endAddress.pincode.toString(),
            destinationState: endAddress.state?.toString(),
            destinationCity: endCity
        }
    }

    await dispatch(searchPetShipperCall(searchParams))
}

export const petDeliverySlice = createSlice({
    name: 'express_delivery',
    initialState,
    reducers: {
        clearExpressDelShippers: (state) => {
            state.results = undefined;
        },
        setPetType: (state,action) => {
            state.pet_type = action.payload
        },
        setPetVaccinated: (state,action) => {
            state.vaccinated = action.payload
        },
        setPetLicensed: (state, action) => {
            state.licensed = action.payload
        },
        setPetMedicallyFit: (state, action) => {
            state.medically_fit = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchPetShipperCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(searchPetShipperCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.results = action.payload.data.data
            })
            .addCase(searchPetShipperCall.rejected, (state) => {
                state.fetching = false
                state.results = []
            })

            .addCase(createPetRequestAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(createPetRequestAsync.fulfilled, (state, action) => {
                state.fetching = false;
            })
            .addCase(createPetRequestAsync.rejected, (state) => {
                state.fetching = false
            })
    }
})

export const { clearExpressDelShippers, setPetVaccinated, setPetLicensed, setPetMedicallyFit, setPetType } = petDeliverySlice.actions;

export const getPetFetching = (state: RootState) => state.pet.fetching
export const getPetResults = (state: RootState) => state.pet.results

export const getPetType = (state: RootState) => state.pet.pet_type
export const getPetVaccinated = (state: RootState) => state.pet.vaccinated
export const getPetLicensed = (state: RootState) => state.pet.licensed
export const getPetMedicallyFit = (state: RootState) => state.pet.medically_fit

export default petDeliverySlice.reducer
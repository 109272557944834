import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBannerImages, getCities, getExportCountries, getCustomerReviews, getHyShippers, getIntShippers, getCoupons, getCMSAnnouncementBarDetails, getPanIndiaShippers } from 'app/api/services';
import { RootState } from 'app/store';

export interface GeneralState {
    hyperlocalShippers: any[],
    internationalShippers: any[],
    panIndiaShippers: any[],
    hyperlocalCities: any[],
    exportCities: any[],
    exportCountries: any[],
    rentalCities: any[],
    bannerImages: any[],
    couponsList: any[]
    rentalVehicles: any,
    customerReviews: any[],
    cmsAnnouncementFeeds: any[],
    fetching: boolean,
}

const initialState: GeneralState = {
    hyperlocalShippers: [],
    internationalShippers: [],
    panIndiaShippers: [],
    hyperlocalCities: [],
    exportCities: [],
    exportCountries: [],
    rentalCities: [],
    bannerImages: [],
    couponsList: [],
    rentalVehicles: {},
    customerReviews: [],
    cmsAnnouncementFeeds: [],
    fetching: false,
}

export const getBannerImagesAction = createAsyncThunk(
    'general/getBannerImages',
    async () => {
        const response = await getBannerImages();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getCouponsAction = createAsyncThunk(
    'general/getCoupons',
    async () => {
        const response = await getCoupons();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getCitiesAction = createAsyncThunk(
    'general/getCities',
    async () => {
        const response = await getCities();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getCustomerReviewsAction = createAsyncThunk(
    'general/getCustomerReviews',
    async () => {
        const response = await getCustomerReviews();
        return response.response
    }
);

export const getExportCountriesAction = createAsyncThunk(
    'general/getExportCountries',
    async () => {
        const response = await getExportCountries();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getHyShippersAction = createAsyncThunk(
    'general/getHyShippers',
    async () => {
        const response = await getHyShippers();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getIntShippersAction = createAsyncThunk(
    'general/getIntShippers',
    async () => {
        const response = await getIntShippers();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getPanIndiaShippersAction = createAsyncThunk(
    'general/getPanIndiaShippers',
    async () => {
        const response = await getPanIndiaShippers();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getCMSDetailsAnnouncementBarAction = createAsyncThunk(
    'general/getCMSDetails',
    async () => {
        const response = await getCMSAnnouncementBarDetails();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);


export const generalSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCitiesAction.pending, (state) => {
            })
            .addCase(getCitiesAction.fulfilled, (state, action) => {
                console.log("CITY", action.payload.data)
                let allCities = action.payload.data.data;
                let hyperlocalCities: any[] = [];
                let exportCities: any[] = [];
                let rentalVehicles: any = {};
                let rentalCities: any[] = [];

                allCities.forEach((city: any) => {
                    if (city.type.hyperlocal.length > 0) {
                        hyperlocalCities.push({
                            cityString: city.cityString,
                            cityText: city.cityText,
                            sw: city.sw,
                            ne: city.ne,
                            state: city.state
                        })
                    }
                    if (city.type.vehicles && city.type.vehicles.length > 0) {
                        rentalVehicles[city.cityString] = city.type.vehicles;
                        rentalCities.push({
                            cityString: city.cityString,
                            cityText: city.cityText,
                            sw: city.sw,
                            ne: city.ne,
                            state: city.state
                        })
                    }
                    if (city.type.international.length > 0) {
                        exportCities.push({
                            cityString: city.cityString,
                            cityText: city.cityText,
                            sw: city.sw,
                            ne: city.ne,
                            state: city.state
                        })
                    }
                    if (city.type.panIndia.length > 0) {
                        //To Do
                    }

                    state.hyperlocalCities = hyperlocalCities;
                    state.exportCities = exportCities;
                    state.rentalVehicles = rentalVehicles;
                    state.rentalCities = rentalCities
                });
                // state.results = action.payload;
            })
            .addCase(getCitiesAction.rejected, (state) => {
            })

            .addCase(getExportCountriesAction.pending, (state) => {
            })
            .addCase(getExportCountriesAction.fulfilled, (state, action) => {
                console.log("COUNTRY", action.payload)
                let allCountries = action.payload.data.data;
                let exportCountries: any[] = [];

                allCountries.forEach((country: any) => {
                    exportCountries.push({
                        countryString: country.countryString,
                        countryText: country.countryText,
                        alphaCode: country.alphaCode
                    })
                });

                state.exportCountries = exportCountries;
            })
            .addCase(getExportCountriesAction.rejected, (state) => {
            })

            .addCase(getHyShippersAction.pending, (state) => {
            })
            .addCase(getHyShippersAction.fulfilled, (state, action) => {
                console.log("HYPERLOCAL SHIPPERS = ", action.payload)
                let data = action.payload.data.data
                state.hyperlocalShippers = data
            })
            .addCase(getHyShippersAction.rejected, (state) => {
            })

            .addCase(getIntShippersAction.pending, (state) => {
            })
            .addCase(getIntShippersAction.fulfilled, (state, action) => {
                console.log("INTERNATIONAL SHIPPERS = ", action.payload)
                let data = action.payload.data.data
                state.internationalShippers = data
            })
            .addCase(getIntShippersAction.rejected, (state) => {
            })

            .addCase(getPanIndiaShippersAction.pending, (state) => {
            })
            .addCase(getPanIndiaShippersAction.fulfilled, (state, action) => {
                console.log("Pan india SHIPPERS = ", action.payload)
                let data = action.payload.data.data
                state.panIndiaShippers = data
            })
            .addCase(getPanIndiaShippersAction.rejected, (state) => {
            })

            .addCase(getBannerImagesAction.pending, (state) => {
            })
            .addCase(getBannerImagesAction.fulfilled, (state, action) => {
                state.bannerImages = action.payload.data.data;
            })
            .addCase(getBannerImagesAction.rejected, (state) => {
            })

            .addCase(getCouponsAction.pending, (state) => {
                state.fetching = true
            })
            .addCase(getCouponsAction.fulfilled, (state, action) => {
                state.fetching = false
                state.couponsList = action.payload.data.data;
            })
            .addCase(getCouponsAction.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getCustomerReviewsAction.pending, (state) => {
                state.fetching = true
            })
            .addCase(getCustomerReviewsAction.fulfilled, (state, action) => {
                console.log("REVIEWS:", action.payload);
                state.fetching = false
                state.customerReviews = action.payload.data.data;
            })
            .addCase(getCustomerReviewsAction.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getCMSDetailsAnnouncementBarAction.pending, (state) => {
                state.fetching = true
            })
            .addCase(getCMSDetailsAnnouncementBarAction.fulfilled, (state, action) => {
                state.fetching = false
                state.cmsAnnouncementFeeds = action.payload.data.data;
            })
            .addCase(getCMSDetailsAnnouncementBarAction.rejected, (state) => {
                state.fetching = false
            })
    }
})

export const { } = generalSlice.actions

export const getBannerImagesState = (state: RootState) => state.general.bannerImages;

export const getHyShippersList = (state: RootState) => state.general.hyperlocalShippers;
export const getIntShippersList = (state: RootState) => state.general.internationalShippers;
export const getPanIndiaShippersList = (state: RootState) => state.general.panIndiaShippers;
export const getHyperlocalCitiesState = (state: RootState) => state.general.hyperlocalCities;
export const getRentalVehicleList = (state: RootState) => state.general.rentalVehicles;
export const getExportCitiesState = (state: RootState) => state.general.exportCities;
export const getExportCountriesState = (state: RootState) => state.general.exportCountries;
export const getRentalCitiesList = (state: RootState) => state.general.rentalCities;
export const getCustomerReviewsState = (state: RootState) => state.general.customerReviews
export const getCouponsList = (state: RootState) => state.general.couponsList
export const getCMSDetailsAnnouncementFeedState = (state: RootState) => state.general.cmsAnnouncementFeeds
export const getGeneralFetchingState = (state: RootState) => state.general.fetching

export default generalSlice.reducer;
import { SearchData } from "../../reducer/panindia/expressdelivery.reducer";
import axios, { AxiosResponse } from "axios";
import { checkLogin } from "../firebase";

const nodeBaseUrl = process.env.REACT_APP_NODE_BASE_URL
const localhostUrl = 'http://localhost:8000'

export interface UpdateData {
    start: {
        originState: string | undefined,
        originPin: string | undefined,
        originCity: string | undefined
    },
    end: {
        destinationState: string | undefined,
        destinationPin: string | undefined,
        destinationCity: string | undefined
    },
    shipperID: string,
    weight: number | undefined,
    user: string | undefined,
    coupon?: string
}

export function searchSameDayDelShipper(formData: SearchData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-shippers/search/express-order`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function initExpressDelOrder(orderData: any) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-orders/express-order/initPayment`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: orderData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

//not required
export function confirmExpressDelOrder(orderData: any) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-orders/express-order/createOrder`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: orderData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function UpdateExpressOrder(formData: UpdateData) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-shippers/resetOrder`,
            method: "POST",
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getExpressOrdersForUser(data: any) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    const { user, page, limit } = data
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-orders/express-orders/findOrders?page=${page}&limit=${limit}`,
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function getSingleExpressOrderForUser(id: string) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-orders/express-orders/${id}`,
            method: "GET",
            headers: {
                Accept: 'application/json'
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { searchIntShipper, IntSearchData, createIntOrder, createIntOrderData, confirmIntOrder, searchImportShipper, createImportOrder, UpdateData, updateQuote } from 'app/api/international';
import { AppThunk, RootState } from 'app/store';
import { getUser } from 'app/reducer/auth.reducer';

export interface Address {
    identifier: string,
    address: string,
    pincode: number,
    lat: number,
    lng: number,
    city?: string,
    country?: string,
    state?: string
}

export interface Order {
    shipper: {
        name: string,
        slug: string,
        packing: boolean,
        tracking: boolean,
        pickup: boolean,
        logoURL: string
    },
    estimate: {
        time: number,
        cost: number,
        costBeforeDiscount?: number,
        gst?: number
        platformCharges?: number
    }
    coupon?: string
}

export interface Person {
    name?: string,
    phone?: string,
    email?: string,
    address?: {
        flatno?: string,
        building?: string,
        fromGoogle?: string
    },
    city?: string,
    zip?: string
}

// export interface endPerson {
//     name?: string,
//     phone?: string,
//     address?: string,
//     city?: string,
//     zip?: string
// }

interface Item {
    name: string,
    qty: number,
    other: string
}

interface Package {
    l: number,
    b: number,
    h: number
}

export interface InternationalOrderState {
    fetching: boolean;
    fetchingError: boolean;
    //export
    startCity: string;
    endCountry: any;
    intCountryAlphaCode: string,
    startAddress: Address;
    startPerson: Person;
    endPerson: Person;
    endAddress: Address;
    serviceType: number;
    orderDetails: Order;
    weight: number;
    shipmentType: string;
    shipmentPurposeType: string;
    cityBounds: any;
    items: Item[];
    pkgAvailable: boolean;
    pkg: Package | undefined;
    volumetric_weight: number;
    results: any[] | undefined;
    bookedAt: any;
    //import
    startCountry: string;
    itemType: string;
    importResults: any[] | undefined;
    importShipper: string,
    ready: boolean;
    customerBusinessInfo?: {
        taxNumber: string,
        invoiceNumber: string
        invoiceDate: string,
        exporterType: string
    }
}

const initialState: InternationalOrderState = {
    fetching: false,
    fetchingError: false,
    startCity: '',
    endCountry: '',
    intCountryAlphaCode: '',
    startAddress: {
        identifier: '',
        address: '',
        pincode: 0,
        lat: 0,
        lng: 0,
        city: '',
        country: '',
        state: ''
    },
    startPerson: {
        name: undefined,
        phone: undefined,
        email: undefined,
        address: {
            flatno: undefined,
            building: undefined
        }
    },
    endPerson: {
        name: undefined,
        phone: undefined,
        email: undefined,
        address: {
            flatno: undefined,
            building: undefined
        },
        city: undefined,
        zip: undefined
    },
    endAddress: {
        identifier: '',
        address: '',
        pincode: 0,
        lat: 0,
        lng: 0,
        city: '',
        country: '',
        state: ''
    },
    serviceType: 0,
    orderDetails: {
        shipper: {
            name: '',
            slug: '',
            packing: false,
            tracking: false,
            pickup: false,
            logoURL: ''
        },
        estimate: {
            time: 0,
            cost: 0,
            costBeforeDiscount: 0
        }
    },
    weight: 0,
    shipmentType: '',
    shipmentPurposeType: '',
    cityBounds: {
        sw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 }
    },
    items: [],
    pkgAvailable: true,
    pkg: undefined,
    volumetric_weight: 0,
    results: undefined,
    bookedAt: undefined,
    startCountry: '',
    itemType: '',
    importResults: undefined,
    importShipper: '',
    ready: false,
    customerBusinessInfo: {
        taxNumber: "",
        invoiceNumber: "",
        invoiceDate: "",
        exporterType: ""
    }
}

export const createIntOrderAsync = (callback: Function): AppThunk => (
    dispatch,
    getState
) => {

    const user = getUser(getState());
    const weight = getIntWeight(getState());
    const city = getIntCityName(getState());
    const country = getIntCountryName(getState());
    const volumetric_weight = getIntVolumetricWeight(getState())

    const startPerson = getIntStartPerson(getState());
    const startAddress = getIntStartAddress(getState());
    const endPerson = getIntEndPerson(getState());
    const endAddress = getIntEndAddress(getState());
    const bookedAt = getIntBookingDate(getState())

    const orderDetails = getIntOrderDetails(getState());
    const items = getIntItems(getState());
    const pkg = getIntPackages(getState());
    const shipmentType = getIntShipmentPurposeType(getState())
    const coupon = getIntCoupon(getState())
    const countryCode = getIntCountryAlphaCode(getState())
    const customerBusinessInfo = getIntCustBusInfo(getState())

    let searchQuery: createIntOrderData = {
        isSuperAdmin: user.isAdmin ? user.isAdmin : false,
        shipperID: orderDetails.shipper.slug,
        user: user.uid,
        email: user.email,
        city: city,
        destination: country,
        weight: (weight > volumetric_weight) ? weight.toString() : volumetric_weight?.toString(),
        bookedAt: bookedAt,
        start: {
            contact: {
                name: startPerson.name as any,
                phone: startPerson.phone?.includes("+") ? startPerson.phone : ("+" + startPerson.phone) as any,
                email: startPerson.email ? startPerson.email : (user.email ? user.email : '')
            },
            address: {
                flatno: startPerson.address?.flatno,
                building: startPerson.address?.building,
                fromGoogle: {
                    address: startAddress.address,
                    lat: startAddress.lat,
                    lng: startAddress.lng,
                    pincode: startAddress.pincode as any
                }
            }
        },
        end: {
            contact: {
                name: endPerson.name as any,
                phone: "+" + endPerson.phone as any
            },
            address: {
                address: {
                    flatno: endPerson.address?.flatno,
                    building: endPerson.address?.building,
                    fromGoogle: {
                        address: endAddress.address,
                        lat: endAddress.lat,
                        lng: endAddress.lng,
                        pincode: endAddress.pincode as any
                    }
                },
                city: endPerson.city as any,
                zip: endPerson.zip as any,
                countryCode: countryCode
            }
        },
        items: items,
        pkg: pkg ? pkg : undefined,
        order_type: "int_export",
        shipmentType: shipmentType,
        customerBusinessInfo:customerBusinessInfo
    }

    let data = {
        searchQuery: searchQuery,
        callback: callback,
        isAdmin: user.isAdmin
    };
    if (coupon) searchQuery.coupon = coupon;
    dispatch(createIntOrderCall(data));

};

const createIntOrderCall = createAsyncThunk(
    'international/createOrder',
    async (data: any) => {

        console.log("DATA: ", data.searchQuery);
        const response = await createIntOrder(data.searchQuery);
        // The value we return becomes the `fulfilled` action payload
        const payload = {
            res: response.response,
            callback: data.callback,
            prevData: data.searchQuery,
            isAdmin: data.isAdmin
        }

        return payload;

    }
);

export const updateIntOrderAsync = (couponCode?: string): AppThunk => (
    dispatch,
    getState
) => {

    const orderDetails = getIntOrderDetails(getState())
    const user = getUser(getState())
    let searchParam: UpdateData = {
        shipperID: orderDetails.shipper.slug,
        destination: getIntCountryName(getState()),
        weight: getIntWeight(getState()),
        user: user.uid,
        shipmentType: getIntShipmentType(getState())
    }

    if (couponCode) searchParam.coupon = couponCode;

    dispatch(updateIntOrderCall(searchParam));

};

const updateIntOrderCall = createAsyncThunk(
    'international/updateOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        const response = await updateQuote(data);
        // The value we return becomes the `fulfilled` action payload
        const payload = {
            res: response.response,
            coupon: data.coupon
        }
        return payload
    }
);

export const searchIntShipperCall = createAsyncThunk(
    'international/searchShipper',
    async (searchQuery: IntSearchData) => {
        const response = await searchIntShipper(searchQuery);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const searchInternationalShipperAsync = (): AppThunk => (
    dispatch,
    getState
) => {

    let searchParam: IntSearchData = {
        city: getIntCityName(getState()),
        country: getIntCountryName(getState()),
        weight: getIntWeight(getState()),
        itemType: getIntShipmentType(getState()),
        shipmentPurpose: getIntShipmentPurposeType(getState())
    }

    dispatch(searchIntShipperCall(searchParam));

};

export const searchImportShipperCall = createAsyncThunk(
    'international/searchImportShipper',
    async () => {
        const response = await searchImportShipper();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const searchImportShipperAsync = (): AppThunk => (
    dispatch,
    getState
) => {

    // let searchParam : IntSearchData = {
    //     city: getIntCityName(getState()),
    //     country: getIntCountryName(getState()),
    //     weight: getIntWeight(getState()),
    // }

    dispatch(searchImportShipperCall());

};

export const confirmIntOrderCall = createAsyncThunk(
    'international/confirmOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        const response = await confirmIntOrder(data);
        return response.response;

    }
);

export const createImportOrderCall = createAsyncThunk(
    'international/createImportOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        const response = await createImportOrder(data);
        return response.response;

    }
);

export const internationalSlice = createSlice({
    name: 'international',
    initialState,
    reducers: {
        setIntStartPerson: (state, action: PayloadAction<any>) => {
            state.startPerson = {
                ...state.startPerson,
                ...action.payload
            }
            // state.startPerson = action.payload;
        },
        setIntStartAddress: (state, action: PayloadAction<any>) => {
            // state.startAddress = action.payload;
            state.startAddress = {
                ...state.startAddress,
                ...action.payload
            }
        },
        setIntEndPerson: (state, action: PayloadAction<any>) => {
            // state.endPerson = action.payload;
            state.endPerson = {
                ...state.endPerson,
                ...action.payload
            }
        },
        setIntEndAddress: (state, action: PayloadAction<any>) => {
            // state.endAddress = action.payload;
            state.endAddress = {
                ...state.endAddress,
                ...action.payload
            }
        },
        setIntItems: (state, action: PayloadAction<any>) => {
            state.items = action.payload;
        },
        setIntPackages: (state, action: PayloadAction<any>) => {
            state.pkg = action.payload;
        },
        setIntBookedAt: (state, action: PayloadAction<any>) => {
            state.bookedAt = action.payload;
        },
        setIntCustomerBusinessInfo: (state, action: PayloadAction<any>) => {
            state.customerBusinessInfo = action.payload
        },
        setIntReady: (state, action: PayloadAction<any>) => {
            state.ready = action.payload;
        },

        setPKGAvailable: (state, action: PayloadAction<any>) => {
            state.pkgAvailable = action.payload;
        },
        setIntVolumetricWeight: (state, action: PayloadAction<any>) => {
            state.volumetric_weight = action.payload
        },

        setIntCityAction: (state, action: PayloadAction<any>) => {
            state.startCity = action.payload;
        },
        setIntCountryAction: (state, action: PayloadAction<any>) => {
            state.endCountry = action.payload;
        },
        setIntCountryAlphaCodeAction: (state, action) => {
            state.intCountryAlphaCode = action.payload
        },
        setIntWeightAction: (state, action: PayloadAction<any>) => {
            state.weight = action.payload;
        },
        setIntTypeAction: (state, action: PayloadAction<any>) => {
            state.shipmentType = action.payload;
        },
        setIntShipmentPurposeAction: (state, action: PayloadAction<any>) => {
            state.shipmentPurposeType = action.payload
        },

        setIntOrderDetails: (state, action: PayloadAction<any>) => {
            const { shipper, estimate } = action.payload
            state.orderDetails.shipper = shipper;
            state.orderDetails.estimate = estimate;
        },


        setImportCountry: (state, action: PayloadAction<any>) => {
            state.startCountry = action.payload;
        },
        setImportItem: (state, action: PayloadAction<any>) => {
            state.itemType = action.payload;
        },
        setImportShipper: (state, action: PayloadAction<any>) => {
            state.importShipper = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(searchIntShipperCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(searchIntShipperCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.results = action.payload.data.data;
                // console.log("response from Shreyas: ", action.payload.data.data);
            })
            .addCase(searchIntShipperCall.rejected, (state) => {
                state.fetching = false
            })

            .addCase(createIntOrderCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(createIntOrderCall.fulfilled, (state, action) => {
                const { res, callback, prevData, isAdmin } = action.payload;
                const { estimate, order } = res.data.data;
                state.fetching = false;
                if (!isAdmin && callback) {
                    callback(order.orderID, order.razorpayID, estimate.amount, prevData);
                }
                else if (isAdmin) {
                    alert("Link sent to the customer")
                }
            })
            .addCase(createIntOrderCall.rejected, (state) => {
                state.fetching = false
                alert("There was an error while creating an order")
            })

            .addCase(confirmIntOrderCall.pending, (state) => {
                state.fetching = true;
                state.fetchingError = true;
            })
            .addCase(confirmIntOrderCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.fetchingError = false;
                // action.payload.navigate('/orders/'+action.payload.orderId);
            })
            .addCase(confirmIntOrderCall.rejected, (state) => {
                state.fetching = false
                alert("Something went wrong while confirming order.");
            })

            .addCase(updateIntOrderCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(updateIntOrderCall.fulfilled, (state, action) => {
                state.fetching = false
                const { res, coupon } = action.payload
                console.log(res, coupon)
                const { amount, breakdown } = res.data.data.estimate
                state.orderDetails.estimate.cost = amount
                state.orderDetails.estimate.costBeforeDiscount = breakdown.baseCost
                state.orderDetails.estimate.gst = breakdown.gst
                state.orderDetails.estimate.platformCharges = breakdown.platformChgs
                if (coupon) {
                    state.orderDetails.coupon = coupon
                } else {
                    state.orderDetails.coupon = undefined
                }
            })
            .addCase(updateIntOrderCall.rejected, (state) => {
                state.fetching = false
            })

            .addCase(searchImportShipperCall.pending, (state) => {
                state.fetching = true;
                state.fetchingError = true;
            })
            .addCase(searchImportShipperCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.importResults = action.payload.data.data;
                // action.payload.navigate('/orders/'+action.payload.orderId);
            })
            .addCase(searchImportShipperCall.rejected, (state) => {
                state.fetching = false
            })

            .addCase(createImportOrderCall.pending, (state) => {
                state.fetching = true;
                state.fetchingError = true;
            })
            .addCase(createImportOrderCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.fetchingError = false;
                // action.payload.navigate('/orders/'+action.payload.orderId);
            })
            .addCase(createImportOrderCall.rejected, (state) => {
                state.fetching = false
                alert("Something went wrong while confirming order.");
            })


    }
})

export const {
    setIntStartPerson,
    setIntStartAddress,
    setIntBookedAt,
    setPKGAvailable,
    setIntEndPerson,
    setIntEndAddress,
    setIntCityAction,
    setIntCountryAction,
    setIntCountryAlphaCodeAction,
    setIntWeightAction,
    setIntTypeAction,
    setIntCustomerBusinessInfo,
    setIntShipmentPurposeAction,
    setIntOrderDetails,
    setIntItems,
    setIntPackages,
    setIntVolumetricWeight,
    setIntReady,
    setImportCountry,
    setImportItem,
    setImportShipper
} = internationalSlice.actions;

export const getIntCityName = (state: RootState) => state.international.startCity;
export const getIntCountryName = (state: RootState) => state.international.endCountry;

export const getIntCountryAlphaCode = (state: RootState) => state.international.intCountryAlphaCode;

export const getIntStartPerson = (state: RootState) => state.international.startPerson;
export const getIntStartAddress = (state: RootState) => state.international.startAddress;

export const getIntEndPerson = (state: RootState) => state.international.endPerson;
export const getIntEndAddress = (state: RootState) => state.international.endAddress;

export const getIntCustBusInfo = (state: RootState) => state.international.customerBusinessInfo;


export const getIntBookingDate = (state: RootState) => state.international.bookedAt;
export const getIntWeight = (state: RootState) => state.international.weight;
export const getIntShipmentType = (state: RootState) => state.international.shipmentType;
export const getIntShipmentPurposeType = (state: RootState) => state.international.shipmentPurposeType;
export const getIntPKGAvailable = (state: RootState) => state.international.pkgAvailable;
export const getIntVolumetricWeight = (state: RootState) => state.international.volumetric_weight

export const getIntItems = (state: RootState) => state.international.items;
export const getIntPackages = (state: RootState) => state.international.pkg;

export const getIntOrderDetails = (state: RootState) => state.international.orderDetails;
export const getIntCoupon = (state: RootState) => state.international.orderDetails.coupon

export const getIntFetching = (state: RootState) => state.international.fetching;
export const getIntFetchingError = (state: RootState) => state.international.fetchingError;

export const getIntReady = (state: RootState) => state.international.ready;

export const getImportShipper = (state: RootState) => state.international.importShipper;
export const getImportCountry = (state: RootState) => state.international.startCountry;



export default internationalSlice.reducer;
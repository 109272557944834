import { Loader } from "@googlemaps/js-api-loader";
import { Loader as LoaderSpinner } from "@mantine/core";
import loadable from '@loadable/component'
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import ReactGA from 'react-ga4';

// import { getAuth } from 'app/reducer/auth.reducer';
import { useViewportSize } from "@mantine/hooks";
import { useAppSelector } from 'app/hooks';
import { AppDispatch } from "./app/store";
import { beReady, getAuth, getReady, getUserAddressAsync, loginFromSession, saveAddress } from "./app/reducer/auth.reducer";
import { getBannerImagesAction, getCitiesAction, getCMSDetailsAnnouncementBarAction, getCustomerReviewsAction, getExportCountriesAction, getHyShippersAction, getIntShippersAction, getPanIndiaShippersAction } from "./app/reducer/general.reducer";

import Header from "./components/general/Header";
import Footer from "./components/general/Footer";
import BottomNav from "./components/general/BottomNav";
import NotificationHeader from "./components/general/NotificationHeader";
// import APSRTC from "./components/apsrtc/APSRTC";
// import APSRTCBookPersonal from "./components/apsrtc/book/BookPersonal";
// import ApsrtcOrders from "./components/orders/apsrtc/ApsrtcOrders";
import { getLoggedInUser } from "./app/api/firebase";
import AgentHeader from "components/agent-store/AgentHeader";
// import APSRTC from "./components/apsrtc/";
// import APSRTCSearchPersonal from "./components/apsrtc/search/SearchPersonal";
// import APSRTCSearchBusiness from "./components/apsrtc/search/SearchBusiness";
// import APSRTCBookPersonal from "./components/apsrtc/book/BookPersonal";
// import APSRTCBookBusiness from "./components/apsrtc/book/BookBusiness";
// import ApsrtcOrders from "./components/orders/apsrtc/ApsrtcOrders";

const Loading = () => {
  return <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}><LoaderSpinner color="violet" /></div>;
};

const Home = loadable(() => import("./pages/Home"), { fallback: <Loading />, });
const AgentHome = loadable(() => import("./pages/agentStore/AgentHome"), { fallback: <Loading />, });
const Profile = loadable(() => import("./pages/Profile"), { fallback: <Loading />, });
const Terms = loadable(() => import("./pages/Terms"), { fallback: <Loading />, });
const FAQ = loadable(() => import("./pages/FAQ"), { fallback: <Loading />, });
const Support = loadable(() => import("./pages/Support"), { fallback: <Loading />, });
const ResetPassword = loadable(() => import("./pages/ResetPassword"), { fallback: <Loading />, });
const GetInTouch = loadable(() => import("./pages/GetInTouch"), { fallback: <Loading />, });
const B2BEnquiry = loadable(() => import("./pages/B2BEnquiry"), { fallback: <Loading />, });
const BecomeShipper = loadable(() => import("./pages/BecomeShipper"), { fallback: <Loading />, });
const ReviewForm = loadable(() => import("./pages/ReviewForm"), { fallback: <Loading />, });
const Feedback = loadable(() => import("./pages/Feedback"), { fallback: <Loading />, });
const TrackOrder = loadable(() => import("./pages/TrackOrder"), { fallback: <Loading />, });
const APSRTCSearchPersonal = loadable(() => import("./components/apsrtc/search/SearchPersonal"), { fallback: <Loading />, });
const APSRTCBookPersonal = loadable(() => import("./components/apsrtc/book/BookPersonal"), { fallback: <Loading />, });
const APSRTCRequestPersonal = loadable(() => import("./components/apsrtc/book/RequestPersonal"), { fallback: <Loading />, });
const C2CRequestForm = loadable(() => import("./pages/c2cRequestForm/c2cRequestForm"), { fallback: <Loading />, });

// import Orders from "./components/orders/Orders";

const Orders = loadable(() => import("./components/orders/Orders"), { fallback: <Loading />, });

// import Rental from "./pages/rental/Rental";
// import RentalSearch from "./pages/rental/Search";
// import RentalBooking from "./pages/rental/Booking";

const Rental = loadable(() => import("./pages/rental/Rental"), { fallback: <Loading />, });
const RentalSearch = loadable(() => import("./pages/rental/Search"), { fallback: <Loading />, });
const RentalBooking = loadable(() => import("./pages/rental/Booking"), { fallback: <Loading />, });

// import TwoWheeler from "./pages/hyperlocal/twoWheeler/TwoWheeler";
// import HySearchResult from "./components/hyperlocal/search/SearchResults";
// import HyBooking from "./pages/hyperlocal/Booking"
// import OnDemand from "./pages/hyperlocal/onDemand/OnDemand";

const TwoWheeler = loadable(() => import("./pages/hyperlocal/twoWheeler/TwoWheeler"), { fallback: <Loading />, });
const HySearchResult = loadable(() => import("./components/hyperlocal/search/SearchResults"), { fallback: <Loading />, });
const HyBooking = loadable(() => import("./pages/hyperlocal/Booking"), { fallback: <Loading />, });
const OnDemand = loadable(() => import("./pages/hyperlocal/onDemand/OnDemand"), { fallback: <Loading />, });

// import Export from "./pages/international/export/Export";
// import IntSearchResults from "./pages/international/export/Search";
// import IntBooking from "./pages/international/export/Booking"

// const Export = React.lazy(() => import('./pages/international/export/Export'));
// const IntSearchResults = React.lazy(() => import('./pages/international/export/Search'));
// const IntBooking = React.lazy(() => import('./pages/international/export/Booking'));

const Export = loadable(() => import("./pages/international/export/Export"), { fallback: <Loading />, });
const IntSearchResults = loadable(() => import("./pages/international/export/Search"), { fallback: <Loading />, });
const IntBooking = loadable(() => import("./pages/international/export/Booking"), { fallback: <Loading />, });

// import PetLogistics from "./components/panindia/petlogistics/PetLogistics";
// import PetLogSearch from "./components/panindia/petlogistics/search/SearchResults";
// import ExpressDelivery from "./components/panindia/expressdelivery/ExpressDelivery";
// import ExpressDeliverySearch from 'pages/panindia/Search'
// import ExpressDeliveryBook from 'pages/panindia/express/Booking'

const PetLogistics = loadable(() => import("./components/panindia/petlogistics/PetLogistics"), { fallback: <Loading />, });
const PetLogSearch = loadable(() => import("./components/panindia/petlogistics/search/SearchResults"), { fallback: <Loading />, });
const ExpressDelivery = loadable(() => import("./components/panindia/expressdelivery/ExpressDelivery"), { fallback: <Loading />, });
const ExpressDeliverySearch = loadable(() => import("./pages/panindia/Search"), { fallback: <Loading />, });
const ExpressDeliveryBook = loadable(() => import("./pages/panindia/express/Booking"), { fallback: <Loading />, });

// import ImportSearch from "./pages/international/import/Search";

const ImportSearch = loadable(() => import("./pages/international/import/Search"), { fallback: <Loading />, });

// import SearchPageLocal from "./components/desktop/local-deliveries/SearchPage";
// import BookRental from "./components/desktop/local-deliveries/rental/Book";
// import BookOnDemand from "./components/desktop/local-deliveries/on-demand/Book";

const SearchPageLocal = loadable(() => import("./components/desktop/local-deliveries/SearchPage"), { fallback: <Loading />, });
const BookRental = loadable(() => import("./components/desktop/local-deliveries/rental/Book"), { fallback: <Loading />, });
const BookOnDemand = loadable(() => import("./components/desktop/local-deliveries/on-demand/Book"), { fallback: <Loading />, });

// import SearchPagePanIndia from "./components/desktop/pan-india/SearchPage";
// import BookPet from "./components/desktop/pan-india/pet/Book";
// import BookExpress from "./components/desktop/pan-india/express/Book";

const SearchPagePanIndia = loadable(() => import("./components/desktop/pan-india/SearchPage"), { fallback: <Loading />, });
const BookPet = loadable(() => import("./components/desktop/pan-india/pet/Book"), { fallback: <Loading />, });
const BookExpress = loadable(() => import("./components/desktop/pan-india/express/Book"), { fallback: <Loading />, });

// import SearchPageInternational from "./components/desktop/international/SearchPage";
// import BookExport from "./components/desktop/international/export/Book";

const SearchPageInternational = loadable(() => import("./components/desktop/international/SearchPage"), { fallback: <Loading />, });
const BookExport = loadable(() => import("./components/desktop/international/export/Book"), { fallback: <Loading />, });




let APIKEY: string;
APIKEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''

ReactGA.initialize('G-0MCHH9XRLH');

declare global {
  interface Window {
    gmapsLoaded: boolean;
    loader: Loader;
    otpless: any;
  }
}

if (!window.gmapsLoaded) {
  console.log('GMAP LOADED');
  const loader = new Loader({
    apiKey: APIKEY,
    version: "weekly",
    libraries: ["places"]
  });
  window.loader = loader;
  window.gmapsLoaded = true;
}
// declare interface RouteProps {
//   path: string;
//   Component: React.ComponentType<any>;
//   isAuth?: true | false | 'loading';
// }

// const PublicRoute = ({ Component }: RouteProps): JSX.Element => (
//   <Route render={(props) => <Component {...props} />} />
// );

const ScrollToTopWrapper = ({ children }) => {
  const location = useLocation();
  const { height, width } = useViewportSize();
  console.log((width < 991) || (location.pathname === "/support"))
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);

  }, [location.pathname, width]);

  useEffect(() => {
    const freshdeskElement = document.getElementById("fc_frame");
    if (freshdeskElement) {
      if ((width < 991) || (location.pathname === "/support")) {
        freshdeskElement.style.display = 'none'
      } else {
        freshdeskElement.style.display = 'initial'
      }
    }
  }, [location.pathname, width])

  return children
}

function RequireAuth({ children }: { children: JSX.Element }) {
  // const [showLoginForm, setShowLoginForm] = useState(true);

  const isAuth = useAppSelector(getAuth);
  console.log('AUTH: ', isAuth);
  // const navigate = useNavigate();
  let location = useLocation();
  console.log('RED: ', location);
  // const { height, width } = useViewportSize();

  if (isAuth !== true) {
    return <>
      <Navigate to={"/?redirect=" + location.pathname} state={{ from: location }} replace />

    </>;
  }

  return children;
}

export function Site() {
  const [onLanding, setOnLanding] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const firstRender = useRef(true)

  // const [signInState, setSignInState] = useState<'number' | 'number-submitted' | 'otp' | 'otp-err' | 'confirmed' | 'new' | 'blocked'>('number');

  // const [blocked, setBlocked] = useState(false)
  // const handleCloseBlock = () => setBlocked(false)
  // const handleShowBlock = () => setBlocked(true)

  // const { innerWidth: width } = window;
  const { height, width } = useViewportSize();
  // const location = useLocation();


  useEffect(() => {

    dispatch(getBannerImagesAction());
    dispatch(getCitiesAction());
    dispatch(getExportCountriesAction());
    dispatch(getCustomerReviewsAction());
    dispatch(getHyShippersAction());
    dispatch(getIntShippersAction());
    dispatch(getPanIndiaShippersAction());
    dispatch(getCMSDetailsAnnouncementBarAction());

    if (firstRender.current) {
      firstRender.current = false
      getLoggedInUser()
        .then((user: any) => {
          console.log("LOGGED IN USER", user);
          const data = {
            token: user?.stsTokenManager?.accessToken
          }
          dispatch(getUserAddressAsync(data))
            .then(res => {
              console.log(res.payload?.data);
              dispatch(saveAddress(res.payload?.data))
            })
            .catch(err => {
              console.log(err);
            })
        })
    }


    const uid = localStorage.getItem('shipmantra-uid');
    const isAdmin = (localStorage.getItem('shipmantra-isAdmin') === "true")
    const username = localStorage.getItem('shipmantra-username');
    const email = localStorage.getItem('shipmantra-email');
    const phone = localStorage.getItem('shipmantra-phone');
    const full_name = localStorage.getItem('shipmantra-full_name');
    const address = localStorage.getItem('shipmantra-address');
    const kyc_filename = localStorage.getItem('shipmantra-kyc_filename') ? localStorage.getItem('kyc_filename') : "";

    if (uid && username && email && phone) {

      const userData = {
        uid, isAdmin,
        username,
        full_name,
        email,
        address: JSON.parse(address || '[]'),
        phone, kyc_filename
      }

      dispatch(loginFromSession(userData));
    } else {
      dispatch(beReady());
    }

  }, []);

  const isAuth = useAppSelector(getAuth);
  const ready = useAppSelector(getReady);

  return (
    <Router>
      {/* {onLanding && <div className="site-banner">
        Flat 40% off on hyperlocal orders*
        <span style={{position:'absolute', zoom:1.2, right:'20px', cursor:'pointer'}} onMouseDown={e=>setOnLanding(false)}><FontAwesomeIcon icon={faTimesCircle} /></span>
      </div>} */}
      <ScrollToTopWrapper>
        {/* <NotificationHeader /> */}
        <Header isAuth={isAuth} onLanding={onLanding} />
        <main>
          <div />
          {
            ready ?
              <React.Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/feedback/:searchParam" element={<Feedback />} />
                  <Route path="/get-in-touch" element={<GetInTouch />} />
                  <Route path="/business-enquiry" element={<B2BEnquiry />} />
                  <Route path="/become-our-shipper" element={<BecomeShipper />} />
                  <Route path="/terms-and-conditions" element={<Terms />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/track" element={<TrackOrder />} />
                  <Route path="/track/:orderID" element={<TrackOrder />} />

                  <Route path="/reset-password/:searchParam" element={<ResetPassword />} />

                  {/* <Route path="/local-delivery/two-wheeler" element={<SearchPageLocal />} /> */}
                  <Route path="/local-delivery/:servicetype" element={<SearchPageLocal />} />
                  <Route path="/local-delivery/rental/book" element={<BookRental />} />
                  <Route path="/local-delivery/:servicetype/book" element={<BookOnDemand />} />
                  {/* <Route path="/local-delivery/:servicetype/book" element={<BookTwoWheeler />} /> */}
                  {/* <Route path="/local-deliveries/:servicetype/book" element={<OrderPageRental />} /> */}
                  <Route path="/local-delivery/" element={<SearchPageLocal />} />

                  {/* <Route path="/local-delivery/two-wheeler" element={<SearchPageLocal />} /> */}
                  <Route path="/pan-india/:servicetype" element={<SearchPagePanIndia />} />
                  <Route path="/pan-india/pet/book" element={<BookPet />} />
                  <Route path="/pan-india/express/book" element={<BookExpress />} />
                  {/* <Route path="/local-delivery/:servicetype/book" element={<BookTwoWheeler />} /> */}
                  {/* <Route path="/local-deliveries/:servicetype/book" element={<OrderPageRental />} /> */}
                  <Route path="/pan-india/" element={<SearchPagePanIndia />} />

                  <Route path="/international-delivery/:servicetype" element={<SearchPageInternational />} />
                  <Route path="/international-delivery/export/book" element={<BookExport />} />
                  {/* <Route path="/local-deliveries/:servicetype/book" element={<OrderPageRental />} /> */}
                  <Route path="/international-delivery/" element={<SearchPageInternational />} />

                  <Route path="/hyperlocal/rental" element={<Rental />} />
                  {/* TO DO = Change route */}
                  <Route path="/hyperlocal/search-rental" element={<RentalSearch />} />
                  <Route
                    path="/hyperlocal/rental/book"
                    element={
                      <RequireAuth>
                        <RentalBooking />
                      </RequireAuth>
                    }
                  />
                  <Route path="/hyperlocal/two-wheeler" element={<TwoWheeler />} />
                  <Route path="/hyperlocal/on-demand" element={<OnDemand />} />
                  <Route path="/hyperlocal/search-results" element={<HySearchResult />} />
                  <Route
                    path="/hyperlocal/book"
                    element={
                      <RequireAuth>
                        <HyBooking />
                      </RequireAuth>
                    }
                  />

                  {/* <Route path="/hyperlocal/search-onDemand" element={<HySearch mode="onDemand" />} /> */}
                  {/* <Route exact={true} path="/hyperlocal/search-rental"><HySearch mode="rental" /></Route> */}



                  <Route path="/international" element={<Export />} />
                  <Route path="/international/:country" element={<Export />} />
                  {/* <Route path="/international/search" element={<IntSearchBar />} /> */}
                  <Route path="/international/search-results" element={<IntSearchResults />} />
                  <Route
                    path="/international/book"
                    element={
                      <RequireAuth>
                        <IntBooking />
                      </RequireAuth>
                    }
                  />

                  <Route path="/import/search-results" element={<ImportSearch />} />

                  <Route
                    path="/orders/:type?/:orderID?"
                    element={
                      <RequireAuth>
                        <Orders />
                      </RequireAuth>
                    }
                  />

                  <Route
                    path="/orders"
                    element={
                      <RequireAuth>
                        <Orders />
                      </RequireAuth>
                    }
                  />

                  <Route
                    path="/review"
                    element={
                      <RequireAuth>
                        <ReviewForm />
                      </RequireAuth>
                    }
                  />

                  {/* **********Pan India********** */}
                  {/* 1. Pet Logistics */}
                  <Route
                    path="/pan-india/pet-logistics"
                    element={
                      <PetLogistics />
                    }
                  />
                  <Route path="/pan-india/pet-logistics/search-results" element={<PetLogSearch />} />

                  {/* 2.Same day delivery/Express delivery */}
                  <Route
                    path="/pan-india/express-delivery"
                    element={
                      <ExpressDelivery />
                    }
                  />
                  <Route path="/pan-india/express-delivery/search-results" element={<ExpressDeliverySearch />} />
                  <Route path="/pan-india/express-delivery/book" element={<ExpressDeliveryBook />} />
                  {/* **********End of Pan India********** */}

                  {/* *************APSRTC Routes************* */}
                  {/* <Route path="/apsrtc/personal" element={<APSRTCSearchPersonal />} />
                  <Route path="/apsrtc/personal/book" element={<APSRTCBookPersonal />} /> */}

                  <Route
                    path="/profile"
                    element={
                      <RequireAuth>
                        <Profile />
                      </RequireAuth>
                    }
                  />

                  {/* *************APSRTC Routes************* */}
                  {/* <Route path="/apsrtc" element={<RequireAuth><APSRTC /></RequireAuth>} /> */}
                  <Route
                    path="/apsrtc/personal"
                    element={
                      // <RequireAuth>
                      <APSRTCSearchPersonal />
                      // </RequireAuth>f
                    }
                  />
                  <Route
                    path="/apsrtc/personal/request"
                    element={
                      <RequireAuth>
                        <APSRTCRequestPersonal />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/apsrtc/personal/book"
                    element={
                      <RequireAuth>
                        <APSRTCBookPersonal />
                      </RequireAuth>
                    }
                  />

                  {/* agent-store */}
                  <Route path="/agent/:agentID" element={<AgentHome setOnLanding={setOnLanding} />}>
                    <Route path="/agent/:agentID/*" element={<AgentHome setOnLanding={setOnLanding} />} />
                  </Route>
                  {/* <Route path="/apsrtc/business" element={<APSRTCSearchBusiness />} /> */}
                  {/* <Route path="/apsrtc/business/book" element={<APSRTCBookBusiness />} /> */}
                  {/* Temp API. Remove later */}
                  {/* <Route path="/apsrtc/orders" element={<ApsrtcOrders />} /> */}


                  <Route path="/c2c-request-form" element={
                    // <RequireAuth>
                      <C2CRequestForm />
                    // </RequireAuth>
                  } />


                  {/* <PrivateRoute path="/orders" isAuth={isAuth} Component={Orders}></PrivateRoute> */}
                  <Route path="/:serviceType?" element={<Home setOnLanding={setOnLanding} />} />
                  <Route index element={<Home setOnLanding={setOnLanding} />} />
                </Routes>
              </React.Suspense>
              :
              <div className="loader-wrapper"><div className="loader-magenta" style={{ zIndex: 8 }} /></div>
          }
        </main>
        <Footer />
        {width < 991 && <BottomNav isAuth={isAuth} onLanding={onLanding} />}
      </ScrollToTopWrapper>
    </Router>
  )

}


export function parseCapital(string:string|undefined) {
    if(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return ''
}

export function time24to12(time24: string) {
    try{
        const hours = parseInt(time24.split(':')[0])
        const minute = parseInt(time24.split(':')[1])
        let time12 = (hours>12?(hours-12).toLocaleString(undefined, {minimumIntegerDigits: 2}):hours)+':'+minute.toLocaleString(undefined, {minimumIntegerDigits: 2})+(hours>12?'PM':'AM')

        return time12
    } catch {
        return ''
    }

}